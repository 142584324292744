import { constraints, uris } from '@app';
import MainFieldResponsiblePage from '@pages/main/MainFieldResponsiblePage';
import { lazy } from 'react';
import {
	createBrowserRouter,
	createRoutesFromElements,
	Route,
	RouterProvider,
} from 'react-router-dom';

import { constants } from './app/constants';
import Layout from './components/templates/Layout';

// HOME
const HomePage = lazy(() => import('./components/pages/home/HomePage'));
const GlossaryPage = lazy(() => import('./components/pages/home/GlossaryPage'));
const RulesPage = lazy(() => import('./components/pages/home/RulesPage'));
const HowItWorksPage = lazy(
	() => import('./components/pages/home/HowItWorksPage')
);
const UsagePlansPage = lazy(
	() => import('./components/pages/home/UsagePlansPage')
);
const SearchResultsPage = lazy(
	() => import('./components/pages/SearchResultsPage')
);
const NotificationsPage = lazy(
	() => import('./components/pages/NotificationsPage')
);
const DoActionPage = lazy(() => import('./components/pages/DoActionPage'));
const ReadQrPage = lazy(() => import('./components/pages/ReadQrPage'));
const TermsPage = lazy(() => import('./components/pages/home/TermsPage'));

// LOGIN / REGISTER
const LoginPage = lazy(() => import('./components/pages/login/LoginPage'));
const RestorePasswordPage = lazy(
	() => import('./components/pages/login/RestorePasswordPage')
);

// MAIN
const MainTournamentManagerPage = lazy(
	() => import('./components/pages/main/MainTournamentManagerPage')
);

const MainAdminPage = lazy(
	() => import('./components/pages/main/MainAdminPage')
);

const MainCoachPage = lazy(
	() => import('./components/pages/main/MainCoachPage')
);

const MainPlayerPage = lazy(
	() => import('./components/pages/main/MainPlayerPage')
);

const MainCollaboratorPage = lazy(
	() => import('./components/pages/main/MainCollaboratorPage')
);

const MainRefereePage = lazy(
	() => import('./components/pages/main/MainRefereePage')
);

const ManagerTeamsPage = lazy(
	() => import('./components/pages/main/ManagerTeamsPage')
);

const AdminTeamsPage = lazy(
	() => import('./components/pages/main/AdminTeamsPage')
);

const ManagerPlayersPage = lazy(
	() => import('./components/pages/main/ManagerPlayersPage')
);

const ManagerRefereesPage = lazy(
	() => import('./components/pages/main/ManagerRefereesPage')
);

const ListNewsPage = lazy(() => import('./components/pages/main/ListNewsPage'));

const AdminRefereesPage = lazy(
	() => import('./components/pages/main/AdminRefereesPage')
);

const AdminPlayersPage = lazy(
	() => import('./components/pages/main/AdminPlayersPage')
);

const CoachPlayersPage = lazy(
	() => import('./components/pages/main/CoachPlayersPage')
);

const RegisterPage = lazy(() => import('./components/pages/RegisterPage'));

// PROFILE
const ProfilePage = lazy(() => import('./components/pages/ProfilePage'));

// TOURNAMENT
const CreateTournamentPage = lazy(
	() => import('./components/pages/tournaments/CreateTournamentPage')
);
const ViewTournamentPage = lazy(
	() => import('./components/pages/tournaments/ViewTournamentPage')
);
const ListTournamentsPage = lazy(
	() => import('./components/pages/tournaments/ListTournamentsPage')
);
const ListStagesPage = lazy(
	() => import('./components/pages/stages/ListStagesPage')
);
const ViewLeaguePage = lazy(
	() => import('./components/pages/stages/ViewLeaguePage')
);
const ViewPlayoffPage = lazy(
	() => import('./components/pages/stages/ViewPlayoffPage')
);
const FinishStagePage = lazy(
	() => import('./components/pages/stages/FinishStagePage')
);
const DefineLeagueMatchesPage = lazy(
	() => import('./components/pages/stages/DefineLeagueMatchesPage')
);
const DefinePlayoffBracketsPage = lazy(
	() => import('./components/pages/stages/DefinePlayoffBracketsPage')
);

// TEAMS
const ViewTeamPage = lazy(() => import('./components/pages/ViewTeamPage'));

// CLUBS
const ClubTablePage = lazy(() => import('./components/pages/ClubTablePage'));
const ViewClubPage = lazy(() => import('./components/pages/ViewClubPage'));

// MATCHES
const ViewVolleyballMatchPage = lazy(
	() => import('./components/pages/matches/ViewVolleyballMatchPage')
);
const ViewBasketballMatchPage = lazy(
	() => import('./components/pages/matches/ViewBasketballMatchPage')
);
const ViewFootballMatchPage = lazy(
	() => import('./components/pages/matches/ViewFootballMatchPage')
);
const CreateFriendlyMatchPage = lazy(
	() => import('./components/pages/matches/CreateFriendlyMatchPage')
);

// FIELDS AND PLACES
const PlacesPage = lazy(() => import('./components/pages/PlacesPage'));
const ViewPlacePage = lazy(() => import('./components/pages/ViewPlacePage'));

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route path="/" element={<Layout />}>
			<Route path="/" element={<HomePage />} />
			<Route path={`/${uris.home}`} element={<HomePage />} />
			<Route path={`/${uris.glossary}`} element={<GlossaryPage />} />
			<Route path={`/${uris.rules}`} element={<RulesPage />} />
			<Route path={`/${uris.howItWorks}`} element={<HowItWorksPage />} />
			<Route path={`/${uris.usagePlans}`} element={<UsagePlansPage />} />
			<Route path={`/${uris.searchResults}`} element={<SearchResultsPage />} />
			<Route path={`/${uris.notifications}`} element={<NotificationsPage />} />
			<Route path={`/${uris.doAction}`} element={<DoActionPage />} />
			<Route path={`/${uris.terms}`} element={<TermsPage />} />
			{/* LOGIN / REGISTER */}
			<Route path={`/${uris.login}`} element={<LoginPage />} />
			<Route
				path={`/${uris.restorePassword}`}
				element={<RestorePasswordPage />}
			/>
			{/* MAIN */}
			<Route
				path={`/${uris.mainTournamentManager}`}
				element={<MainTournamentManagerPage />}
			/>
			<Route path={`/${uris.mainAdmin}`} element={<MainAdminPage />} />
			<Route path={`/${uris.mainCoach}`} element={<MainCoachPage />} />
			<Route path={`/${uris.mainPlayer}`} element={<MainPlayerPage />} />
			<Route
				path={`/${uris.mainCollaborator}`}
				element={<MainCollaboratorPage />}
			/>
			<Route path={`/${uris.mainReferee}`} element={<MainRefereePage />} />
			<Route
				path={`/${uris.mainFieldResponsible}`}
				element={<MainFieldResponsiblePage />}
			/>
			<Route path={`/${uris.managerTeams}`} element={<ManagerTeamsPage />} />
			<Route
				path={`/${uris.managerPlayers}`}
				element={<ManagerPlayersPage />}
			/>
			<Route
				path={`/${uris.managerReferees}`}
				element={<ManagerRefereesPage />}
			/>
			<Route path={`/${uris.listNews}`} element={<ListNewsPage />} />
			<Route path={`/${uris.adminReferees}`} element={<AdminRefereesPage />} />
			<Route path={`/${uris.adminTeams}`} element={<AdminTeamsPage />} />
			<Route path={`/${uris.adminPlayers}`} element={<AdminPlayersPage />} />
			<Route path={`/${uris.coachPlayers}`} element={<CoachPlayersPage />} />
			<Route path={`/${uris.listStages}`} element={<ListStagesPage />} />
			<Route path={`/${uris.register}`} element={<RegisterPage />} />
			{/* PROFILE */}
			<Route path={`/${uris.profile}`} element={<ProfilePage />} />
			{/* TOURNAMENTS */}
			<Route
				path={`/${uris.createTournament}`}
				element={<CreateTournamentPage />}
			/>
			<Route
				path={`/${uris.viewTournament}`}
				element={<ViewTournamentPage />}
			/>
			<Route
				path={`/${uris.listTournaments}`}
				element={<ListTournamentsPage />}
			/>
			<Route path={`/${uris.viewLeague}`} element={<ViewLeaguePage />} />
			<Route path={`/${uris.viewPlayoff}`} element={<ViewPlayoffPage />} />
			<Route path={`/${uris.finishStage}`} element={<FinishStagePage />} />
			<Route
				path={`/${uris.defineLeagueMatches}`}
				element={<DefineLeagueMatchesPage />}
			/>
			<Route
				path={`/${uris.definePlayoffBrackets}`}
				element={<DefinePlayoffBracketsPage />}
			/>
			{/* TEAMS */}
			<Route path={`/${uris.viewTeam}`} element={<ViewTeamPage />} />

			{/* CLUBS */}
			<Route path={`/${uris.clubsTable}`} element={<ClubTablePage />} />
			<Route path={`/${uris.viewClub}`} element={<ViewClubPage />} />

			{/* MATCHES */}
			<Route
				path={`/${uris.viewVolleyballMatch}`}
				element={<ViewVolleyballMatchPage />}
			/>
			<Route
				path={`/${uris.viewBasketballMatch}`}
				element={<ViewBasketballMatchPage />}
			/>
			<Route
				path={`/${uris.viewFootball11Match}`}
				element={<ViewFootballMatchPage />}
			/>
			<Route
				path={`/${uris.viewFootball5Match}`}
				element={
					<ViewFootballMatchPage
						initialMaxStarters={constraints.MAX_STARTERS_F5}
						initialMinStarters={constraints.MIN_STARTERS_F5}
						defaultPenaltiesQuantity={
							constants.INTERMEDIATE_FOOTBALL_DEFAULT_PENALTY_AMOUNT
						}
						isFoulCountEnabled
					/>
				}
			/>
			<Route
				path={`/${uris.viewBabyFootballMatch}`}
				element={
					<ViewFootballMatchPage
						defaultPenaltiesQuantity={
							constants.INTERMEDIATE_FOOTBALL_DEFAULT_PENALTY_AMOUNT
						}
					/>
				}
			/>
			<Route
				path={`/${uris.viewFootball7Match}`}
				element={
					<ViewFootballMatchPage
						initialMaxStarters={constraints.MAX_STARTERS_F7}
						initialMinStarters={constraints.MIN_STARTERS_F7}
						defaultPenaltiesQuantity={
							constants.INTERMEDIATE_FOOTBALL_DEFAULT_PENALTY_AMOUNT
						}
					/>
				}
			/>
			<Route
				path={`/${uris.viewFootball8Match}`}
				element={
					<ViewFootballMatchPage
						initialMaxStarters={constraints.MAX_STARTERS_F8}
						initialMinStarters={constraints.MIN_STARTERS_F8}
						defaultPenaltiesQuantity={
							constants.INTERMEDIATE_FOOTBALL_DEFAULT_PENALTY_AMOUNT
						}
					/>
				}
			/>
			<Route
				path={`/${uris.createFriendlyMatch}`}
				element={<CreateFriendlyMatchPage />}
			/>
			{/* FIELDS */}
			<Route path={`/${uris.fields}`} element={<PlacesPage />} />
			<Route path={`/${uris.viewPlace}`} element={<ViewPlacePage />} />

			{/* QRS */}
			<Route path={`/${uris.qr}`} element={<ReadQrPage />} />
		</Route>
	),
	{
		future: {
			// Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
			v7_normalizeFormMethod: true,
		},
	}
);

export default () => <RouterProvider router={router} />;
