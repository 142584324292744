import {
	checkExpiredSession,
	loginAuth,
	logoutAction,
} from '@actions/AuthActions';
import { getOptionsFromEnum } from '@actions/ConvertActions';
import { hasCapability, isInProduction } from '@actions/GenericActions';
import {
	createInactiveUserAction,
	getNotificationsAction,
} from '@actions/UserActions';
import { isValidEmail } from '@actions/ValidationActions';
import { constants, constraints, texts, uris } from '@app';
import { FlexibleIcon } from '@assets/icons';
import Modal from '@atoms/Modal';
import Select from '@atoms/Select';
import useLoggedUser from '@hooks/useLoggedUser';
import SearchBar from '@molecules/SearchBar';
import { setIsLoading, setIsNotLoading } from '@reducers/GenericReducer';
import {
	EnumCapabilities,
	EnumSports,
	EnumUserTypes,
	type Result,
} from '@types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';

type HeaderProps = {
	setHash?: (hash: string) => void;
};

const Header = ({ setHash }: HeaderProps) => {
	const { loggedUser } = useLoggedUser();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const searchBarRef = useRef<HTMLInputElement>(null);

	const profilePanelElement = useRef<HTMLDivElement>(null);
	const showProfilePanelButtonElement = useRef<HTMLButtonElement>(null);

	const [searchParams] = useSearchParams();
	const urlParamSearchValue = searchParams.get(uris.paramSearch);
	const urlParamSearchFilter = searchParams.get(uris.paramSearchFilter);
	const [searchFilter, setSearchFilter] = useState(
		urlParamSearchFilter ?? texts.usersAndTeams
	);
	const [searchValue, setSearchValue] = useState(urlParamSearchValue ?? '');

	const [isShowingProfilePanel, setIsShowingProfilePanel] = useState(false);
	const [
		isShowingCreateTournamentManagerModal,
		setIsShowingCreateTournamentManagerModal,
	] = useState(false);
	const [tournamentManagerEmail, setTournamentManagerEmail] = useState('');
	const [sport, setSport] = useState(EnumSports.football11);
	const [isShowingSearchBar, setIsShowingSearchBar] = useState(false);

	const handleSearch = useCallback(
		(newSearchValue: string) => {
			setSearchValue(newSearchValue);

			if (newSearchValue.length < constraints.SEARCH_CHARACTERS_MIN) {
				return;
			}

			if (newSearchValue.includes('%') && isInProduction()) {
				toast.error(texts.errorSearchCharacter);
				return;
			}
			navigate(
				`/${uris.searchResults}?${uris.paramSearch}=${newSearchValue}&${uris.paramSearchFilter}=${searchFilter}`
			);
		},
		[navigate, searchFilter]
	);

	const handleLogout = useCallback(() => {
		dispatch(setIsLoading());
		dispatch(
			logoutAction(() => {
				dispatch(setIsNotLoading());
				if (!loginAuth()) {
					navigate('/');
				}
			}) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
		);
	}, [dispatch, navigate]);

	const handleCreateTournamentManager = useCallback(
		(adaptedEmail: string) => {
			dispatch(setIsLoading());
			dispatch(
				createInactiveUserAction(
					adaptedEmail,
					EnumUserTypes.TOURNAMENT_MANAGER,
					sport,
					(result: Result) => {
						dispatch(setIsNotLoading());
						if (!result.success) {
							toast.error(result.message ?? texts.errorGeneric());
							return;
						}

						toast.success(
							texts.tournamentManagerCreatedMustCompleteRegistration
						);
					}
				) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
			);
		},
		[sport, dispatch]
	);

	useEffect(() => {
		dispatch(setIsNotLoading()); // To remove loader at the begining of any page (with header), once.
		if (loggedUser.id) {
			dispatch(
				checkExpiredSession((result: boolean) => {
					if (result && loggedUser.id) {
						dispatch(
							getNotificationsAction((result2: Result) => {
								if (!result2?.success) {
									if (result2?.message !== '') toast.error(result2.message);
								}
							}) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
						);
					}
				}) as unknown as AnyAction // TODO: Remove this type conversion when the action file be converted to TS.
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		document.addEventListener('mousedown', (event) => {
			if (
				isShowingProfilePanel &&
				profilePanelElement.current &&
				!profilePanelElement.current.contains(event.target as Node) &&
				showProfilePanelButtonElement &&
				!showProfilePanelButtonElement.current?.contains(event.target as Node)
			) {
				setIsShowingProfilePanel(false);
			}
		});

		document.addEventListener('keydown', (event) => {
			if (isShowingProfilePanel && event.key === 'Escape') {
				setIsShowingProfilePanel(false);
			}
		});
	}, [isShowingProfilePanel]);

	const getNavigationPath = useCallback(() => {
		const {
			isAdmin,
			isCoach,
			isPlayer,
			isReferee,
			isFieldResponsible,
			isTournamentManager,
			isCollaborator,
		} = loggedUser;

		if (isAdmin) return uris.mainAdmin;
		if (isCoach) return uris.mainCoach;
		if (isPlayer) return uris.mainPlayer;
		if (isCollaborator) return uris.mainCollaborator;
		if (isReferee) return uris.mainReferee;
		if (isFieldResponsible) return uris.mainFieldResponsible;
		if (isTournamentManager) return uris.mainTournamentManager;
		return uris.home;
	}, [loggedUser]);

	const renderCreateTournamentManagerModal = useMemo(
		() => (
			<Modal
				title={texts.createTournamentManager}
				content={
					<div className="w-full p-6">
						<p className="mx-auto flex text-xl text-gray-500 break-words">
							{texts.explanationOfTournamentManagerCreation}
						</p>
						<input
							className="w-full border-2 border-gray-600 text-login-field color-text-1 text-base md:text-2xl"
							value={tournamentManagerEmail}
							onChange={(event) => {
								setTournamentManagerEmail(event.target.value);
							}}
							spellCheck={false}
							placeholder={texts.email}
							onKeyDown={(event) => {
								if (event.key === 'Enter') {
									event.preventDefault();
									document.getElementById('sport-select')?.focus();
								}
							}}
						/>
						<Select<EnumSports>
							id="sport-select"
							handleChange={(selectedSport) => {
								setSport(selectedSport as EnumSports);
							}}
							options={getOptionsFromEnum<EnumSports>(
								Object.entries(EnumSports)
							)}
							initialValue={sport}
							className="textfield-1 text-sm sm:text-xl placeholder-gray-300 border sm:border-4 w-full"
						/>
					</div>
				}
				okButtonText={texts.createTournamentManager}
				okButtonAction={() => {
					const adaptedEmail = tournamentManagerEmail.trim();
					if (!isValidEmail(adaptedEmail)) {
						toast.error(texts.emailNotValid);
						return;
					}
					setIsShowingCreateTournamentManagerModal(false);
					handleCreateTournamentManager(adaptedEmail);
				}}
				cancelButtonText={texts.cancel}
				cancelButtonAction={() => {
					setIsShowingCreateTournamentManagerModal(false);
				}}
				startOpened={isShowingCreateTournamentManagerModal}
				closeAfterOk={false}
			/>
		),
		[
			tournamentManagerEmail,
			sport,
			isShowingCreateTournamentManagerModal,
			handleCreateTournamentManager,
		]
	);

	const renderProfilePanel = useMemo(
		() => (
			<div className="w-full flex justify-end pr-2" ref={profilePanelElement}>
				<div className="w-48 shadow-lg z-50">
					<div className="color-bg-9 flex">
						<img
							className="w-10 h-10 flex m-2 object-cover rounded-full"
							src={`${process.env.REACT_APP_BACKEND_URL}/${
								loggedUser.profilePhotoUri ?? 'media/images/profile.png'
							}`}
							alt={texts.notifications}
						/>
						<div className="flex-col w-full my-2">
							<span className="profile-panel-button-1 text-xl justify-center">
								{loggedUser.nickname}
							</span>
							<span className="profile-panel-button-1 text-sm justify-center">
								{texts.getUserTypeText(loggedUser)}
							</span>
						</div>
					</div>
					<div className="flex-col color-bg-3">
						<button
							type="button"
							className="profile-panel-button-2 p-1 flex w-full"
							onClick={() => {
								if (window.location.pathname !== `/${getNavigationPath()}`) {
									navigate(`/${getNavigationPath()}`);
								}
								setIsShowingProfilePanel(false);
							}}>
							<img
								className="w-6 h-6 flex my-1 mx-2"
								src={`${process.env.REACT_APP_BACKEND_URL}/media/images/main-user-icon.png`}
								alt={texts.main}
							/>
							<span className="my-auto">{texts.main}</span>
						</button>
					</div>
					<div className="flex-col color-bg-3">
						<button
							type="button"
							className="profile-panel-button-2 p-1 flex w-full"
							onClick={() => {
								/**
								 * Will only reload the page when coming
								 * from ViewProfile of another user.
								 */
								const shouldReload =
									window.location.pathname === `/${uris.profile}` &&
									window.location.search !==
										`?${uris.paramUserId}=${loggedUser.id}`;
								navigate(
									`/${uris.profile}?${uris.paramUserId}=${loggedUser.id}`
								);
								setIsShowingProfilePanel(false);
								if (shouldReload) {
									window.location.reload();
								}
								if (setHash) setHash('');
							}}>
							<img
								className="w-6 h-6 flex my-1 mx-2"
								src={`${process.env.REACT_APP_BACKEND_URL}/media/images/edit-profile-icon.png`}
								alt={texts.editProfile}
							/>
							<span className="my-auto">{texts.editProfile}</span>
						</button>
					</div>

					{(hasCapability(
						loggedUser.capabilities,
						EnumCapabilities.CAPABILITY_CREATE_TOURNAMENT
					) ||
						loggedUser.isTournamentManager) && (
						<div className="flex-col color-bg-3">
							<button
								type="button"
								className="profile-panel-button-2 p-1 flex w-full"
								onClick={() => {
									/**
									 * Will only reload the page when coming
									 * from ListTournaments with another filter.
									 */
									const shouldReload =
										window.location.pathname === `/${uris.listTournaments}` &&
										window.location.search !==
											`?${uris.paramFilterResults}=${constants.FILTER_MY_TOURNAMENTS}`;

									navigate(
										`/${uris.listTournaments}?${uris.paramFilterResults}=${constants.FILTER_MY_TOURNAMENTS}`
									);
									setIsShowingProfilePanel(false);
									if (shouldReload) window.location.reload();
								}}>
								<img
									className="w-6 h-6 flex my-1 mx-2"
									src={`${process.env.REACT_APP_BACKEND_URL}/media/images/my-tournaments-icon.png`}
									alt={texts.myTournaments}
								/>
								<span className="my-auto">{texts.myTournaments}</span>
							</button>
						</div>
					)}

					{hasCapability(
						loggedUser.capabilities,
						EnumCapabilities.CAPABILITY_CREATE_TOURNAMENT
					) && (
						<div className="flex-col color-bg-3">
							<button
								type="button"
								className="profile-panel-button-2 p-1 flex w-full"
								onClick={() => {
									setIsShowingProfilePanel(false);
									navigate(`/${uris.createTournament}`);
								}}>
								<img
									className="w-6 h-6 flex my-1 mx-2"
									src={`${process.env.REACT_APP_BACKEND_URL}/media/images/create-tournament-icon.png`}
									alt={texts.createTournament}
								/>
								<span className="my-auto">{texts.createTournament}</span>
							</button>
							<button
								type="button"
								className="profile-panel-button-2 p-1 flex w-full"
								onClick={() => {
									setIsShowingProfilePanel(false);
									navigate(`/${uris.clubsTable}`);
								}}>
								<img
									className="w-6 h-6 flex my-1 mx-2"
									src={`${process.env.REACT_APP_BACKEND_URL}/media/images/create-tournament-icon.png`}
									alt={texts.clubsTable}
								/>
								<span className="my-auto">{texts.clubsTable}</span>
							</button>
						</div>
					)}

					{hasCapability(
						loggedUser.capabilities,
						EnumCapabilities.CAPABILITY_CREATE_TOURNAMENT_MANAGER
					) && (
						<div className="flex-col color-bg-3">
							<button
								type="button"
								className="profile-panel-button-2 p-1 flex w-full"
								onClick={() => {
									setIsShowingCreateTournamentManagerModal(true);
									setIsShowingProfilePanel(false);
								}}>
								<img
									className="w-6 h-6 flex my-1 mx-2"
									src={`${process.env.REACT_APP_BACKEND_URL}/media/images/new-tournament-manager-icon.png`}
									alt={texts.createTournamentManager}
								/>
								<span className="my-auto">{texts.createTournamentManager}</span>
							</button>
						</div>
					)}
					{(loggedUser.isPlayer ||
						loggedUser.isCoach ||
						loggedUser.isCollaborator) && (
						<>
							<div className="flex-col color-bg-3">
								<button
									type="button"
									className="profile-panel-button-2 p-1 flex w-full"
									onClick={() => {
										navigate(
											`/${uris.profile}?${uris.paramUserId}=${loggedUser.id}#${uris.hashParamTeams}`
										);
										setIsShowingProfilePanel(false);
										if (setHash) setHash(uris.hashParamTeams);
									}}>
									<img
										className="w-6 h-6 flex my-1 mx-2"
										src={`${process.env.REACT_APP_BACKEND_URL}/media/images/my-teams-icon.png`}
										alt={texts.myTeams}
									/>
									<span className="my-auto">{texts.myTeams}</span>
								</button>
							</div>

							<div className="flex-col color-bg-3">
								<button
									type="button"
									className="profile-panel-button-2 p-1 flex w-full"
									onClick={() => {
										navigate(`/${uris.createFriendlyMatch}`);
										setIsShowingProfilePanel(false);
									}}>
									<img
										className="w-6 h-6 flex my-1 mx-2"
										src={`${process.env.REACT_APP_BACKEND_URL}/media/images/matches-icon.png`}
										alt={texts.createFriendlyMatch}
									/>
									<span className="my-auto">{texts.createFriendlyMatch}</span>
								</button>
							</div>
						</>
					)}
					{loggedUser.isReferee && (
						<div className="flex-col color-bg-3">
							<button
								type="button"
								className="profile-panel-button-2 p-1 flex w-full"
								onClick={() => {
									/**
									 * Will only reload the page when coming
									 * from ViewProfile of another user.
									 */
									const shouldReload =
										window.location.pathname === `/${uris.profile}` &&
										window.location.search !==
											`?${uris.paramUserId}=${loggedUser.id}`;
									navigate(
										`/${uris.profile}?${uris.paramUserId}=${loggedUser.id}#${uris.hashParamMatches}`
									);
									setIsShowingProfilePanel(false);
									if (setHash) setHash(uris.hashParamMatches);
									if (shouldReload) window.location.reload();
								}}>
								<img
									className="w-6 h-6 flex my-1 mx-2"
									src={`${process.env.REACT_APP_BACKEND_URL}/media/images/referee-matches-icon.png`}
									alt={texts.myMatches}
								/>
								<span className="my-auto">{texts.myMatches}</span>
							</button>
						</div>
					)}
					<div className="w-full color-bg-3">
						<div className="w-2/3 mx-auto border border-gray-600" />
					</div>
					<div className="flex-col color-bg-3">
						<button
							type="button"
							className="profile-panel-button-2 p-1 flex w-full"
							onClick={() => {
								setIsShowingProfilePanel(false);
								handleLogout();
							}}>
							<img
								className="w-6 h-6 flex my-1 mx-2"
								src={`${process.env.REACT_APP_BACKEND_URL}/media/images/logout-icon-2.png`}
								alt={texts.logout}
							/>
							<span className="my-auto">{texts.logout}</span>
						</button>
					</div>
				</div>
			</div>
		),
		[handleLogout, navigate, getNavigationPath, setHash, loggedUser]
	);

	const renderUserInfoOrLogin = useMemo(
		() => (
			<div className="flex content-around w-full justify-end col-span-2">
				{!loggedUser.id ? (
					<div className="flex my-auto gap-1 xl:gap-4 2xl:gap-8">
						<button
							type="button"
							className="flex mx-auto text-header-button-1 text-xs px-1 border rounded-md    xs:w-24     sm:text-base     md:text-2xl md:w-32"
							onClick={() => {
								navigate(`/${uris.login}`);
							}}>
							{texts.loginOrRegister}
						</button>
					</div>
				) : (
					<div className="flex flex-col my-auto sm:w-5/6">
						<div className="flex overflow-hidden">
							<a
								href={`/${uris.notifications}`}
								title={texts.notifications}
								className="flex my-auto justify-center w-1/2">
								<img
									className="w-5 h-5 sm:w-7 sm:h-7"
									src={`${process.env.REACT_APP_BACKEND_URL}${
										loggedUser.notifications?.find(
											(notification: { wasRead?: boolean }) =>
												!notification.wasRead
										) !== undefined
											? '/media/images/notifications-icon.png'
											: '/media/images/notifications-empty-icon.png'
									}`}
									alt={texts.notifications}
								/>
							</a>
							<button
								type="button"
								ref={showProfilePanelButtonElement}
								onClick={() => setIsShowingProfilePanel(!isShowingProfilePanel)}
								title={texts.myProfile}
								className="flex my-auto w-1/2">
								<img
									className="w-8 h-8 sm:w-10 sm:h-10 flex mx-auto object-cover rounded-full"
									src={`${process.env.REACT_APP_BACKEND_URL}/${
										loggedUser.profilePhotoUri ?? 'media/images/profile.png'
									}`}
									alt={texts.profileImage}
								/>
							</button>
						</div>
						<span className="text-white text-xs sm:text-lg overflow-hidden flex justify-center max-h-8 sm:h-12">
							{loggedUser.nickname}
						</span>
					</div>
				)}
			</div>
		),
		[isShowingProfilePanel, navigate, loggedUser]
	);

	const handleSetSearchFilter: React.Dispatch<React.SetStateAction<string>> = (
		newSearchFilter: React.SetStateAction<string>
	) => {
		setSearchFilter(newSearchFilter);
		setTimeout(() => searchBarRef.current?.focus(), 100);
	};

	return (
		<div
			className={`shadow-lg w-full color-bg-2 ${
				isShowingSearchBar ? 'h-28' : 'h-16'
			}     sm:h-24`}>
			<div className="grid grid-cols-9 px-1 h-full    sm:px-5     md:gap-3">
				<div className="flex justify-center h-full flex-wrap content-around col-span-2      sm:col-span-1">
					<a
						className="flex cursor-pointer h-5/6 w-16     sm:w-auto"
						href={uris.home}>
						<FlexibleIcon
							height={64}
							width={100}
							iconURL={`${
								process.env.REACT_APP_BACKEND_URL
							}/media/images/logo-mt${!isInProduction() ? '-testing' : ''}.png`}
							altText={texts.multiTournament}
						/>
					</a>
				</div>
				<div className="hidden col-span-5 mx-1     sm:flex sm:col-span-6">
					<SearchBar
						searchFilter={searchFilter}
						searchValue={searchValue}
						setSearchFilter={handleSetSearchFilter}
						handleSearch={handleSearch}
						inputRef={searchBarRef}
					/>
				</div>
				<div className="flex justify-center items-center h-full col-span-5 mx-1    sm:hidden sm:col-span-6">
					<button
						type="button"
						title={texts.manageTournamentManagers}
						onClick={() => {
							setIsShowingSearchBar(!isShowingSearchBar);
							if (!isShowingSearchBar) {
								setTimeout(() => searchBarRef.current?.focus(), 100);
							}
						}}>
						<img
							className="flex h-10 object-contain"
							src={`${
								process.env.REACT_APP_BACKEND_URL
							}/media/images/search-transparent${
								isShowingSearchBar ? '-disabled' : ''
							}-icon.png`}
							alt={texts.search}
						/>
					</button>
				</div>

				{renderUserInfoOrLogin}
				{isShowingSearchBar && (
					<div className="flex col-span-9 h-10    sm:hidden">
						<SearchBar
							searchFilter={searchFilter}
							searchValue={searchValue}
							setSearchFilter={handleSetSearchFilter}
							handleSearch={handleSearch}
							inputRef={searchBarRef}
						/>
					</div>
				)}
			</div>

			{isShowingProfilePanel && renderProfilePanel}
			{isShowingCreateTournamentManagerModal &&
				renderCreateTournamentManagerModal}
		</div>
	);
};

export default Header;
