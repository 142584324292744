import type { Observation, UserIsRole } from '@types';
import {
	EnumEntityTypes,
	EnumGenders,
	EnumParticipationStatuses,
	EnumSides,
	EnumSports,
	EnumStageStatuses,
	EnumSuspension,
} from '@types';
import dayjs from 'dayjs';
import locale from 'dayjs/locale/es';
import duration from 'dayjs/plugin/duration';

import constraints from '../constraints';
import { eTexts } from './e';
import { mTexts } from './m';
import uris from './uris';

dayjs.locale(locale);
dayjs.extend(duration);

const contactEmail = 'contacto@multitorneos.panaweb.uy';

const texts = {
	// --A--
	accept: 'Aceptar',
	accepted: 'Aceptado',
	acceptedParticipationInTournament: (stageName: string) =>
		`¡Aceptaron participar en ${stageName}!`,
	acceptRequestToJoinTeam: (userNickname: string, teamName: string) =>
		`¡Aceptaron la solicitud de ${userNickname} para unirse a ${teamName}!`,
	actions: 'Acciones',
	actionNotFound: 'No se encontró la acción',
	activity: 'Actividad',
	activeUser: 'Activar usuario',
	add: 'Agregar',
	addEvent: 'Agregar evento',
	addExistingStage: 'Agregar competencia existente',
	addFirstPlayerToDefineCategory:
		'Agregá el primer integrante para definir la categoría.',
	addFoul: 'Agregar falta',
	addLeague: 'Agregar Liga',
	addOrganizer: 'Agregar directivo',
	addingTeam: 'Agregar equipos...',
	addingReferee: 'Agregando árbitro...',
	addingMember: 'Agregando miembro...',
	addCoaches: 'Agregar entrenadores...',
	addCollaborators: 'Agregar colaboradores...',
	addCumulativeLeague: 'Agregar liga acumulativa',
	addOpenStage: 'Agregar competencia abierta',
	addPlayers: 'Agregar jugadores...',
	addPosition: 'Agregar posición',
	addReferees: 'Agregar árbitros...',
	address: 'Dirección',
	addSet: 'Agregar set',
	addStage: 'Agregar competencia',
	addSubstitution: 'Agregar cambio',
	addTeam: 'Agregar equipo',
	addTeams: 'Agregar equipos...',
	addUser: 'Agregar usuario',
	askToJoinTeam: 'Solicitar unirse al equipo',
	admin: 'Administrador',
	all: 'Todo',
	allFemale: 'Todas',
	alreadyRegistered: '¿Ya estás registrado? Entonces deberías...',
	anyDoubts: '¿Tenés dudas?',
	ascends: 'Asciende',
	ascendLeague: 'Liga de ascenso',
	ascendsTo: 'Ascienden a: ',
	assistant: 'Asistente',
	assistantReferee: 'Asistente',
	assistantReferees: 'Asistentes',
	assistantRefereesNotInvited: 'Ningún árbitro asistente ha sido invitado.',
	assistantRefereeNotAccepted: (nickname: string) =>
		`${nickname} no ha aceptado la participación.`,
	assistantRefereeRejected:
		'Un árbitro asistente ha rechazado la participación.',
	areYouSureChangingSport: 'Se van a quitar los equipos de las competencias',
	areYouSureCancellingCreation: 'Se va a cancelar la creación',
	areYouSureDeletingTeam: 'Se va a borrar el equipo',
	areYouSureRemovingStage: 'Se va a eliminar la competencia',
	areYouSureRemovingTeam: 'Se va a quitar el equipo de la competencia',
	areYouSureRemovingTournament: 'Se va a borrar el torneo',
	areYouSurePublishRound: 'Se va a publicar la fecha',
	areYouSurePublishMatch: 'Se va a publicar el partido',
	areYouSureRestoringTournament: 'Al restaurar se perderán los cambios',
	areYouSureSendInvite: 'Se va a invitar a un amistoso.',
	areYouSureFinishSet: 'Cuidado: Se va a terminar el set',
	areYouSureFinishMatch: 'Cuidado: Se va a terminar el partido',
	areYouSureFinishMatchEdition:
		'Cuidado: Se va a terminar la edición del partido',
	areYouSureFinishStage: 'Cuidado: Se va a terminar la competencia',
	areYouSureFinishStageWithoutTransition:
		'Cuidado: Se va a terminar la competencia sin hacer transiciones a próximas competencias',
	areYouSureAcceptMember: (nickname: string) => `Se va a aceptar a ${nickname}`,
	areYouSureAcceptInvitation: `Se va a aceptar la invitación`,
	areYouSureRejectMember: (nickname: string) =>
		`Se va a rechazar a ${nickname}`,
	areYouSureRejectInvitation: `Se va a rechazar la invitación`,
	areYouSurePublishStage: (stageName: string) =>
		`Se va a publicar la competencia ${stageName}`,
	areYouSurePublishPlayoffs: (stageName: string) =>
		`Se van a publicar los play-offs ${stageName}`,
	areYouSureConvertToUser: `Se va a convertir un noUsuario a usuario`,
	areYouSureDeleteImage: `Se va a eliminar la imagen`,
	areYouSureDeleteNoUser: `Se va a eliminar un noUsuario`,
	areYouSureDeleteMember: (nickname: string) => `Se va a quitar a ${nickname}`,
	areYouSureUndoInvitation: (nickname: string) =>
		`Se va a retirar la invitación a ${nickname}`,
	areYouSureUndoRequest: `Se va a retirar la solicitud de unirse`,
	areYouSureUnsetAsOrganizer: (nickname: string) =>
		`Se va a remover a ${nickname} como directivo`,
	areYouSureSetAsManager: (nickname: string) =>
		`Se va a designar a ${nickname} como dirigente`,
	areYouSureUnsetAsManager: (nickname: string) =>
		`Se va a remover a ${nickname} como dirigente`,
	arrivingIndications: 'Cómo llegar',
	availableTimeouts: 'Tiempos muertos disponibles',

	// --B--
	babyFootball: 'Baby fútbol',
	babyFootballCategoryNotYet: 'Aún no tiene edad para baby fútbol.',
	babyFootballCategory5Years: dayjs().subtract(5, 'years').year(),
	babyFootballCategory6Years: dayjs().subtract(6, 'years').year(),
	babyFootballCategory7Years: dayjs().subtract(7, 'years').year(),
	babyFootballCategory8Years: dayjs().subtract(8, 'years').year(),
	babyFootballCategory9Years: dayjs().subtract(9, 'years').year(),
	babyFootballCategory10Years: dayjs().subtract(10, 'years').year(),
	babyFootballCategory11Years: dayjs().subtract(11, 'years').year(),
	babyFootballCategory12Years: dayjs().subtract(12, 'years').year(),
	babyFootballCategory13Years: dayjs().subtract(13, 'years').year(),
	babyFootballCategoryU9: 'Sub-9',
	babyFootballCategoryU11: 'Sub-11',
	babyFootballCategoryU13: 'Sub-13',
	babyFootballCategoryAlreadyFinished: 'Ya pasó la etapa de baby fútbol.',
	basketball: 'Básquetbol',
	backgroundColor: 'Color de fondo',
	breakImage: 'Imagen de descanso',
	carouselColor: 'Color del carousel',
	birthDate: 'Fecha de nacimiento',
	birthDateAbbreviated: 'Fecha nac.',
	boardManager: 'Delegado de mesa',
	bodyColor: 'Color del cuerpo',
	both: 'Ambos',
	bracket: 'Llave',
	bracketAmount: 'Cantidad de cruces',
	bracketMatches: 'Partidos de la llave',
	bracketsNotDefinedYet:
		'Las llaves del playoff aún no están definidas. Te estamos redirigiendo.',
	bye: 'Bye',

	// --C--
	cancel: 'Cancelar',
	cancelCompleteManually: 'Cancelar',
	cancelCreation: 'Cancelar creación',
	cancellingCreationWillLooseChanges:
		'Al cancelar la creación se perderán los cambios hechos.',
	canManage: 'Puede gestionar',
	cantDefineMatchesDueToUnaccepted:
		'Todos deben aceptar para poder definir cruces',
	cantManage: 'No puede gestionar',
	cantSaveTiedMatch:
		'No se puede guardar el partido porque no está definido el ganador',
	cannotTransitionBecauseItHasNotTransitions:
		'No se puede transicionar, no tiene transiciones salientes',
	cannotTransitionBecauseItsAlreadyTransitioned:
		'No se puede transicionar, la liga acumulativa ya fue transicionada',
	cannotTransitionBecauseExistLeagueNotFinished:
		'No se puede transicionar, existen ligas acumuladas no terminadas',
	captchaNotValidated: 'El captcha no fue validado',
	carouselImages: 'Imágenes del carousel',
	carousel: 'Carousel',
	category: 'Categoría',
	cellphone: 'Número de celular',
	change: 'Cambiar',
	changeSport: 'Cambiar deporte',
	changePassword: 'Cambiar contraseña',
	changingSportWillRemoveEveryInvitedTeam:
		'Cambiar el deporte hará que se desinviten todos los equipos de las competencias',
	chooseReferee: 'Elegir árbitro',
	city: 'Ciudad',
	close: 'Cerrar',
	club: 'Club',
	clubs: 'Clubs',
	clubsTable: 'Tabla de clubs',
	clubIdentifier: 'Id',
	clubName: 'Nombre del club',
	clubNameCannotBeEmpty: 'El nombre del club no puede estar vacío.',
	clubTableNameCannotBeEmpty:
		'El nombre de la tabla de clubes no puede estar vacío.',
	clubNameMaxLengthError: (maxLength: number, currentLength: number) =>
		`El nombre del club no puede tener más de ${maxLength} caracteres, actualmente tiene ${currentLength}.`,
	coach: 'Entrenador',
	coachAssistant: 'Ayudante Técnico',
	coachResume: 'DT',
	coaches: 'Entrenadores',
	coachOfTeam: (nickname: string) =>
		`${nickname} es el valiente Director Técnico de este equipo`,
	collapse: 'Contraer',
	collaborator: 'Colaborador',
	collaborators: 'Colaboradores',
	competitionMode: 'Modo de competición',
	competitionGender: 'Género de competición',
	completeRegistration: 'Completar registro',
	completeManually: 'Completar manualmente',
	concept: 'Concepto',
	confirmParticipation: 'Confirmar participación',
	updateParticipationList: 'Actualizar lista de participación',
	confirm: 'Confirmar',
	confirmDeleteNews: 'Se va a borrar la noticia.',
	confirmMatchEditionEnd: 'Se va a terminar la de edición del partido',
	confirmMatchEnd: 'Se va a terminar el partido',
	confirmTransitionCumulativeLeague:
		'Se va a transicionar la liga acumulativa, no podrás volver a transicionarla luego.',
	contact: 'Contacto',
	contactPerson: 'Persona de contacto',
	contactPhone: 'Teléfono de contacto',
	contactEmail,
	continuousInMT: 'Sigo en MultiTorneos',
	continueCreating: 'Seguir creando',
	convertToUser: 'Convertir a usuario',
	country: 'País',
	coverImage: 'Imagen de portada',
	copyLink: 'Copiar enlace',
	createClub: 'Crear club',
	createClubTable: 'Crear tabla de clubes',
	createCumulativeLeague: 'Crear liga acumulativa',
	createField: 'Crear cancha',
	createPlace: 'Crear complejo',
	createEvent: 'Crear evento',
	createFriendly: 'Crear amistoso',
	createFriendlyMatchFor: (teamName: string) => `Amistoso con ${teamName}`,
	createFriendlyMatch: `Amistoso`,
	createTeam: 'Crear equipo',
	createNews: 'Crear Noticia',
	createTeamOf: 'Crear equipo de ',
	createTournament: 'Crear torneo',
	createTournamentManager: 'Crear organizador de torneos',
	creatingField: 'Se está creando cancha...',
	creatingTournamentManager: 'Se está creando el organizador de torneos...',
	createMembers: 'Crear Integrantes',
	creatingClub: 'Creando club',
	creatingClubTable: 'Creando tabla de clubes',
	creatingNewTournament: 'Creando nuevo torneo',
	creatingTeam: 'Creando equipo',
	creator: 'Creador',
	cumulateNewLeague: 'Acumular nueva liga',
	cumulatedLeagues: 'Ligas acumuladas',
	cumulativeAlreadyTransitioned: 'Ya se transicionó esta acumulativa',
	cumulativeLeague: 'Liga acumulativa',
	cumulativeLeagueHasNotEnoughCumulatedLeagues: (name: string) =>
		`La liga acumulativa ${name} tiene menos de 2 ligas acumuladas.`,
	cumulativeLeagueNotFound: 'Liga acumulativa no encontrada',
	cumulativeLeagueTransitionsWereApplied:
		'Se aplicaron las transiciones de la liga acumulativa',

	// --D--
	date: 'Fecha',
	dateFrom: 'Desde',
	dateTo: 'Hasta',
	defineLeagueMatches: 'Definir cruces',
	definePlayoffBrackets: 'Definir llaves',
	delete: 'Borrar',
	deleteCumulativeLeague: 'Eliminar liga acumulativa',
	deleteEvent: 'Eliminar evento',
	deleteImage: 'Borrar imagen',
	deleteNews: 'Eliminar noticia',
	deleteNoUser: 'Eliminar noUsuario',
	deleteStage: 'Eliminar competencia',
	deleteTeam: 'Eliminar equipo',
	deleteTournament: 'Eliminar torneo',
	deletingTeam: 'No te preocupes, el equipo se podrá restaurar más adelante.',
	descendLeague: 'Liga de descenso',
	descends: 'Desciende',
	descendsTo: 'Descienden a: ',
	description: 'Descripción',
	descriptionRequired: 'La descripción es requerida',
	destinationPlayoff: 'Playoff de destino',
	disqualified: 'Descalificado',
	doAction: 'Realizar acción',
	document: 'documento',
	documentIdentifier: 'Cédula',
	documentIdentifierInitials: 'CI',
	documentNumber: 'Número de Documento',
	doesntAnsweredYet: 'No respondió aún',
	doesntBelongToAnyTeamYet: 'No pertenece a ningún equipo por ahora',
	doesntHasAnyMatchYet: 'No tiene partidos por ahora',
	dontWaitForRefereeConfirmation: 'Designar árbitros sin confirmación',
	download: 'Descargar',
	drawnMatches: 'PE',

	// --E--
	...eTexts,

	// --F--
	facebook: 'Facebook',
	female: 'Femenino',
	field: 'Cancha',
	fields: 'Canchas',
	fieldsAndPlaces: 'Canchas/Complejos',
	final: 'Final',
	finalScore: 'Resultado final',
	finalText: 'Texto final',
	finishesGoalkeeping: 'Sale',
	finishesPlaying: 'Sale',
	fieldName: 'Cancha (nombre o número)',
	fieldCreated: '¡La cancha fue creada!',
	fieldCannotBeEmpty: 'Tenés que rellenar todos los campos.',
	fieldResponsible: 'Responsable de canchas',
	filesRejectedBecauseOfType: 'Se rechazaron archivos debido a sus tipos.',
	filterNotFound: 'Filtro no reconocido',
	filterTeamsInProcess: 'Filtrar equipos en formación',
	finishedMatches: 'Terminados',
	finishedStagesCannotHaveNewTransition: (stageName: string) =>
		`No se puede crear la transición porque la competencia "${stageName}" ya está terminada.`,
	finishedStagesCannotLostTransitions: (stageName: string) =>
		`No se puede borrar la transición porque la competencia "${stageName}" ya está terminada.`,
	finishing: 'Terminando',
	finishingPeriod: 'Terminando tiempo',
	finishSet: 'Terminar set',
	finishMatch: 'Terminar partido',
	finishMatchEdition: 'Terminar edición del partido',
	finishPeriod: (periodNumber: number) =>
		periodNumber === 1
			? 'Terminar primer tiempo'
			: periodNumber === 2
			? 'Terminar segundo tiempo'
			: `Terminar tiempo ${periodNumber}`,
	finishLeague: 'Terminar liga',
	finishPlayoff: 'Terminar playoff',
	finishStage: 'Terminar competencia',
	finishStageAndApplyChanges: 'Aplicar transiciones y terminar',
	finishStageWithoutTransition: 'Sólo terminar competencia',
	flagrantFoul: 'Antideportiva',
	football11: 'Fútbol 11',
	football5: 'Fútbol 5',
	football7: 'Fútbol 7',
	football8: 'Fútbol 8',
	formatDate: (date: Parameters<typeof dayjs>[0]) =>
		dayjs(date).format('DD/MM/YYYY'),
	formatDateWithText: (date: Parameters<typeof dayjs>[0]) =>
		dayjs(date).format('DD [de] MMM [de] YYYY'),
	formatDatetime: (datetime: Parameters<typeof dayjs>[0]) =>
		dayjs(datetime).format('HH:mm [del] D [de] MMMM [de] YYYY'),
	formatDatetimeToBrackets: (datetime: string) =>
		dayjs(datetime).format('D MMM [-] HH:mm'),
	formatDatetimeToForm: (datetime: Parameters<typeof dayjs>[0]) =>
		dayjs(datetime).format('DD/MM/YYYY [-] HH:mm'),
	// Format id number to look like this: 4.596.500-3
	formatIdNumber: (idNumber: number) => {
		const idNumberString = String(idNumber);
		const number = Number(idNumberString.slice(0, -1));
		const digit = idNumberString.slice(-1);
		const numberString = number.toLocaleString('es-UY');
		return `${numberString}-${digit}`;
	},
	formatMatchDate: (datetime: string) =>
		dayjs(datetime).format('D [de] MMMM [de] YYYY'),
	formatMatchTime: (datetime: string) => dayjs(datetime).format('H:mm a'),
	formatTime: (seconds: number) => {
		const durationInSeconds = dayjs.duration(seconds, 'seconds');
		return `${String(durationInSeconds.minutes()).padStart(2, '0')}:${String(
			durationInSeconds.seconds()
		).padStart(2, '0')}`;
	},
	formatTwoDigits: (number: number) => `${String(number).padStart(2, '0')}`,
	forStage: (stageName: string) => `para la competencia ${stageName}`,
	follow: 'Seguir',
	followEntityAsSpectatorExplanation:
		'Para poder seguir conceptos y que te informemos de los acontecimientos relacionados a ellos precisamos tu email',
	foulTypes: 'Tipos de Faltas',
	friendlyMatch: 'Amistoso',
	friendlyMatches: 'Partidos amistosos',
	friendlyMatchAccepted: '¡Aceptaron un amistoso!',
	friendlyMatchAcceptedBetween: (localName: string, visitorName: string) =>
		`Aceptaron la invitación para el amistoso entre ${localName} y ${visitorName}.`,
	friendlyMatchRejectedBetween: (localName: string, visitorName: string) =>
		`Rechazaron la invitación para el amistoso entre ${localName} y ${visitorName}.`,
	friendlyMatchRejected: '¡Rechazaron un amistoso!',
	fullName: 'Nombre Completo',

	// --G--
	gameMode: 'Modo de juego',
	generatePdf: 'Generar PDF',
	generateXls: 'Generar XLS',
	getConceptName: (concept: EnumEntityTypes) => {
		switch (concept) {
			case EnumEntityTypes.field:
				return texts.field.toLowerCase();
			case EnumEntityTypes.league:
				return texts.league.toLowerCase();
			case EnumEntityTypes.playoff:
				return texts.playoff.toLowerCase();
			case EnumEntityTypes.match:
				return texts.match.toLowerCase();
			case EnumEntityTypes.team:
				return texts.team.toLowerCase();
			case EnumEntityTypes.tournament:
				return texts.tournament.toLowerCase();
			default:
				return texts.notDefined.toLowerCase();
		}
	},
	getConceptValue: (concept: string): EnumEntityTypes => {
		switch (concept) {
			case texts.field.toLowerCase():
				return EnumEntityTypes.field;
			case texts.league.toLowerCase():
				return EnumEntityTypes.league;
			case texts.playoff.toLowerCase():
				return EnumEntityTypes.playoff;
			case texts.match.toLowerCase():
				return EnumEntityTypes.match;
			case texts.team.toLowerCase():
				return EnumEntityTypes.team;
			case texts.tournament.toLowerCase():
				return EnumEntityTypes.tournament;
			default:
				return EnumEntityTypes.tournament;
		}
	},
	getGenderText: (gender?: EnumGenders) =>
		gender === EnumGenders.male
			? texts.male.toLowerCase()
			: gender === EnumGenders.female
			? texts.female.toLowerCase()
			: texts.notDefined,
	getPhaseText: (phaseNumber: number, id: number) =>
		phaseNumber === -1
			? texts.thirdPlaceDefinition
			: phaseNumber === 0
			? texts.final
			: phaseNumber === 1
			? `${texts.semiFinal} - ${id + 1}`
			: phaseNumber === 2
			? `${texts.roundOf8} - ${id + 1}`
			: phaseNumber === 3
			? `${texts.roundOf16} - ${id + 1}`
			: `${texts.roundOf(2 ** phaseNumber)} - ${id + 1}`,
	getUserTypeText: (user: UserIsRole) =>
		user.isPlayer
			? texts.player
			: user.isCoach
			? texts.coach
			: user.isCollaborator
			? texts.collaborator
			: user.isReferee
			? texts.referee
			: user.isFieldResponsible
			? texts.fieldResponsible
			: user.isAdmin
			? texts.admin
			: user.isTournamentManager
			? texts.tournamentManager
			: texts.notDefined,
	gkStarter: 'Arquero titular',
	glossary: 'Glosario',
	goal: 'Gol',
	goalsByPenalty: 'Goles de penal',
	goalsBySecondPenalty: 'Goles de penal largo',
	goalsDiff: '+/- goles',
	goldenCup: 'Copa de oro',
	google: 'Google',
	goToMatch: 'Ir al partido',
	goToMatchBetweenByLink: (localName: string, visitorName: string) =>
		`Ir al partido entre ${localName} y ${visitorName} en el siguiente enlace: `,

	// --H--
	hashtag: '#',
	hasLost: (loser: string, plural?: boolean) =>
		`${plural ? 'Perdieron' : 'Perdió'}: ${loser}`,
	hasThirdPlaceDefinition: '¿Tiene definición de 3er puesto?',
	headerColor: 'Color de fondo del cabezal',
	height: 'Altura',
	heightInCms: 'Altura en centímetros',
	here: 'acá',
	hideReads: 'Ocultar leídas',
	hideDeleted: 'Ocultar eliminadas',
	home: 'Home',
	homeText1: 'La forma más simple y rápida de organizar torneos deportivos',
	homeText2: 'Gestión profesional de torneos amateur',
	homeText3: 'Podemos darte una mano',
	homeText4:
		'Una solución para crear y administrar tus torneos de punta a punta, desde el registro automatizado de participantes, pasando por la creación y programación de torneos, hasta la gestión de partidos en tiempo real.',
	homeText5: 'Registros sin estrés',
	homeText5_1:
		'Un proceso de registro a la medida de tu torneo con formularios fáciles de usar.',
	homeText5_2:
		'Adiós al ingreso manual de datos. Los equipos y jugadores se autoinscriben a tus torneos y partidos.',
	homeText6: 'Menos papeleo. Más registros. Más torneos.',
	homeText6_1: 'Múltiples divisiones dentro del mismo torneo.',
	homeText6_2: 'Ascensos y descensos entre competencias.',
	homeText6_3: 'Registro del torneo en el acto.',
	homeText7: 'Menos esfuerzo para el organizador de torneos',
	homeText7_1: 'Gestión de las fechas, partidos y resultados en tiempo real.',
	homeText7_2: 'Las tablas y los cruces se generan automáticamente.',
	homeText8: 'Automatizá el trabajo manual',
	homeText8_1: 'Ahorrá tiempo con tablas, cruces y clasificaciones automáticas',
	homeText8_2:
		'Comunicá la información de los partidos a jugadores, entrenadores y árbitros en pocos pasos',
	homeText9: 'Múltiples organizadores en torneos',
	homeText9_1: 'Compartí la administración.',
	homeText9_2: 'Involucrá a los dirigentes en la gestión de los planteles.',
	homeText9_3: 'Involucrá a los árbitros en la actualización de resultados.',
	homeText10:
		'Gestioná competencias en computadora o celular desde cualquier lugar.',
	homeText10_1: 'Los datos no se pierden, quedan automáticamente en la nube.',
	homeText10_2:
		'Llevá el control de tus competencias con datos accesibles desde cualquier dispositivo.',
	homeText10_3:
		'Las interfaces gráficas se ven bien sin importar el tamaño de la pantalla.',
	homeText11: 'Organizá una competencia fácilmente.',
	homeText11_1:
		'Organizá competencias en modo liga, playoffs o fase de grupos con definición en playoffs.',
	homeText12: '¡Generá un playoff!',
	homeText12_1:
		'Los participantes compiten en cruces de dos donde el ganador de cada cruce avanza hasta la final.',
	homeText12_2: 'Con opción de un cruce por el tercer puesto.',
	homeText12_3:
		'Los cruces son a 1 partido o al mejor de varios partidos, como prefieras vos.',
	homeText12_4: 'Con un elegante diseño de llaves.',
	homeText13: 'Generá fixtures para eliminación simple y playoffs',
	homeText13_1:
		'Creá una primera fase con tantos grupos como quieras y hacé que los equipos mejor clasificados jueguen en playoffs.',
	homeText14: 'Organizá partidos amistosos.',
	homeText14_1:
		'No es necesario organizar un torneo completo, el sistema te permite organizar un único partido amistoso con facilidad y eficiencia.',
	homeText14_2: 'Ideal para partidos esporádicos.',
	homeText15: 'Mejor comunicación con los participantes.',
	homeText15_1:
		'Equipos, árbitros y espectadores informados en todo momento y lugar.',
	homeText16: 'Publicá horarios y ubicación de partidos.',
	homeText16_1: 'Asegurate de que todos sepan dónde y cuándo se juega.',
	homeText16_2: 'Informá al instante los cambios de último momento.',
	homeText16_3: 'Notificaciones y comunicados por la web y por email.',
	homeText17: 'Valorizá a los participantes.',
	homeText17_1: 'Perfil personal editable.',
	homeText17_2: 'Perfil de equipo editable.',
	homeText17_3: 'Información de los equipos y partidos.',
	homeText18: 'Noticias, fotos y videos de los torneos.',
	homeText18_1: 'Interactuá con noticias, fotos y vídeos.',
	homeText18_2: 'Seguir eventos, personas y equipos favoritos.',
	homeText19:
		'Sistema de gerenciamiento de torneos deportivos desarrollado por Panaweb.',
	homeText20: 'Descargo:',
	homeText20_1:
		' debido a que la terminología de las competencias deportivas suele utilizar términos similares para identificar actividades muy diferentes, en MultiTorneos hemos adoptado para el uso en el sistema la terminología que se define en el',
	horizontal: 'Horizontal',
	horizontalNews: 'Noticia horizontal',
	howItWorks: 'Cómo funciona',
	howItWorksText1:
		'Una herramienta simple y rápida para organizar torneos deportivos',
	howItWorksText2: 'Cómo usar MultiTorneos',
	howItWorksText3:
		'Para usar MultiTorneos debés registrarte primero y luego podés ingresar al sistema.',
	howItWorksText4: 'Registro',
	howItWorksText4_1:
		'para registrarte tenés que tocar en Registrarte en la parte superior derecha de esta página y seguir las indicaciones. Podés registrarte como: jugador, entrenador, árbitro o colaborador.',
	howItWorksText5: 'Ingreso',
	howItWorksText5_1:
		'si ya estás registrado en el sistema, podés ingresar tocando en "Ingresar" en la parte superior derecha de esta página.',
	howItWorksText6: 'Perfil',
	howItWorksText6_1:
		'una vez que ingreses podrás editar tu perfil, subir fotos y completar tus datos personales.',
	howItWorksText7: 'Crear Equipos',
	howItWorksText7_1:
		'si te registraste como jugador, entrenador o colaborador, podés crear tu propio equipo, invitar integrantes y subir imágenes.',
	howItWorksText8: '¿Cómo organizar torneos y competencias?',
	howItWorksText9:
		'Para poder organizar torneos y competencias, es necesario estar registrado en el sistema como organizador de torneos (OT).',
	howItWorksText10:
		'Para eso debés ponerte en contacto con nosotros enviando un email a',
	howItWorksText10_1: ', así podremos informarte de nuestros Planes de uso.',
	howItWorksText11:
		'Recibirás un email con instrucciones para acceder y usar el sistema.',
	howItWorksText12: '¿Qué podés hacer como organizador de torneos?',
	howItWorksText13:
		'MultiTorneos te ofrece todas las herramientas necesarias para organizar tus torneos y competencias con facilidad y sin errores.',
	howItWorksText14: 'Con MultiTorneos podés rápidamente :',
	howItWorksText15: 'Crear torneos y agregarle competencias.',
	howItWorksText16: 'Gestionar las competencias ya sean ligas o play-offs.',
	howItWorksText17: 'Gestionar los partidos.',
	howItWorksText18:
		'Compartir los tanteadores de los encuentros en tiempo real.',
	howItWorksText19: '¿Cómo encontrar algo en MultiTorneos?',
	howItWorksText20:
		'Con el buscador que está en la cabecera de todas las páginas del sistema podés encontrar jugadores, equipos, partidos, torneos y canchas que estén registrados en el sistema.',
	howItWorksText21:
		'El uso de MultiTorneos es gratuito para jugadores, entrenadores, colaboradores y árbitros',
	howToGetCoordinates:
		'Abrí la ubicación del complejo y copiá de allí las coordenadas (latitud y longitud). Cuando las ingreses el mapa debería centrarse en el complejo.',

	// --I--
	iAcceptThe: 'Acepto los',
	idNumberRequired:
		'El documento de identidad es requerido para jugadores de baby fútbol',
	ifYouWishToReSendEmailTouch: 'Si deseás re enviar el email, presioná',
	ifYouSignOutDontLoginAgain:
		'Si te retirás no podrás volver a ingresar hasta que te registres nuevamente',
	ifYouLeaveTeamCantJoinWithoutAcceptance:
		'Si te retirás podés volver al equipo solicitando tu incorporación al dirigente.',
	invalidPeriod: 'Tiempo inválido',
	invalidSetBecauseMatchAlreadyFinished: (set: { number: number }) =>
		`El set número ${set.number} es inválido porque el partido ya terminó con los sets anteriores.`,
	image: 'Imagen',
	images: 'Imágenes',
	imageSaved: '¡La imagen ha sido guardada correctamente!',
	imageNotUploadedYet:
		'Todavía no se puede usar de perfil porque no se terminó de subir, intentá de nuevo en unos segundos',
	inProcess: 'En formación',
	inProcessTeamsCannotBeInvited: (teamName: string) =>
		`El equipo "${teamName}" está en proceso de formación, por eso no se lo puede invitar.`,
	inProgress: 'En curso',
	inCourseMatches: 'Partidos en directo',
	insertFieldName: 'Ingresá el nombre de la cancha',
	insertYourEmailToRestorePassword:
		'Ingresá tu dirección de email y te enviaremos un correo para que restaures la contraseña',
	insertYourEmailToReSendValidation:
		'Ingresá tu dirección de email y te enviaremos un correo para que valides tu usuario',
	invitationAccepted: 'Invitación aceptada',
	invitationRejected: 'Invitación rechazada',
	inviteCoach: 'Invitar entrenador',
	inviteOrganizer: 'Invitar directivo',
	invitedAs: 'Te invitaron como',
	inviteCollaborator: 'Invitar colaborador',
	invitePlayer: 'Invitar jugador',
	inviteTeam: 'Invitar equipo',
	inviteYourTeamToTournament: (teamName: string, stageName: string) =>
		`Invitaron al equipo ${teamName} a participar de la competencia ${stageName} con el mensaje: "¡Te esperamos!". Podés ver la información de la competencia en`,
	invitationSent:
		'Invitación enviada, te enviaremos una notificación cuando respondan',
	internalImage: 'Imagen interna',
	internalLocation: 'Ubicación interna',
	internalView: 'Vista interna',

	// --J--
	joinTeam: 'Unirse',

	// --L--
	lastLocalScore: 'Último puntaje del local',
	lastVisitorScore: 'Último puntaje del visitante',
	latitude: 'Latitud',
	league: 'Liga',
	leagueAlreadyCumulated: 'La liga ya está acumulada en esta liga acumulativa',
	leagueAlreadyTransitionedCannotCumulate:
		'No se pueden acumular nuevas ligas porque la liga acumulativa ya fue transicionada',
	leagueAlreadyTransitionedCannotStopCumulating:
		'No se pueden quitar ligas acumuladas porque la liga acumulativa ya fue transicionada',
	leaguesList: 'Lista de Ligas',
	leagueToAdd: 'Liga a agregar',
	leagueWasPublished: '¡La liga ha sido publicada!',
	leagueWasNotPublishedYet:
		'La competencia aún no ha sido publicada,  te hemos redirigido a la home',
	leavePageQuestion: '¿Deseás salir sin guardar?',
	leaveTeam: 'Retirarse del Equipo',
	leaveTeamShort: 'Retirarse',
	leaveTeamSuccess: 'Te retiraste del equipo correctamente',
	lessParticipantsAcceptedThan: (minParticipants: number) =>
		`Muy pocos participantes aceptados, como mínimo la competencia admite ${minParticipants}.`,
	linemanReferee: 'Árbitro de línea',
	linemanRefereeNotAccepted: (nickname: string) =>
		`${nickname} no ha aceptado la participación.`,
	linemanRefereeRejected: 'Un árbitro de línea ha rechazado la participación.',
	linemanReferees: 'Árbitros de línea',
	linemanRefereesNotEnough: 'Debe haber 2 árbitros de línea.',
	linkToLeague: '+',
	link: 'link',
	linkCopiedSuccessfully: 'Enlace copiado al portapapeles',
	listNews: 'Listar Noticias',
	listTournaments: 'Listar torneos',
	listStages: 'Listar competencias',
	listUpcomingStages: 'Listar próximas competencias',
	upcomingStages: 'Próximas competencias',
	inCourseStages: 'Competencias en curso',
	listInCourseStages: 'Listar competencias en curso',
	liveMatches: 'Partidos en directo',
	loadImages: 'Cargar imágenes',
	loading: 'Cargando',
	loadingMatch: 'Cargando partido',
	loadingProfile: 'Cargando perfil',
	location: 'Ubicación',
	local: 'Local',
	localScore: 'Puntaje del local',
	login: 'Ingresar',
	loginOrRegister: 'Ingresá o registrate',
	loginFail:
		'El email y la contraseña no corresponden a un usuario, por favor revisá y volvé a intentarlo.',
	logIntoMT: 'Ingresá a MultiTorneos',
	loginWithGoogle: 'Ingresar con Google',
	loginWithFacebook: 'Ingresar con Facebook',
	logo: 'Logo',
	logout: 'Salir',
	longitude: 'Longitud',
	lostMatches: 'PP',

	// --M--
	...mTexts,

	// --N--
	name: 'Nombre',
	needAtLeastOneLeague: 'Se necesita al menos una liga para generar la tabla.',
	newGoldenCup: 'Nueva copa de oro',
	newLeague: 'Nueva liga',
	newPlayoff: 'Nuevos playoffs',
	newSilverCup: 'Nueva copa de plata',
	newOpenStage: 'Nueva competencia abierta',
	news: 'Noticias',
	newsCreatedSuccessfully: 'La noticia fue creada correctamente.',
	newsDeleted: 'La noticia fue eliminada correctamente.',
	newsImage: 'Imagen de la noticia',
	newsUpdated: 'La noticia fue actualizada correctamente.',
	next: 'Siguiente',
	nextStage: 'Competencias próximas',
	nextMatch: 'Próximo partido',
	nextMatchNotPublished: 'El próximo partido aún no está publicado',
	nextMatches: 'Próximos',
	nextTodayMatches: 'Próximos partidos',
	nextMatchesFor: 'Próximos partidos para',
	nickname: 'Nombre de usuario',
	nicknameNotValid: `El nombre de usuario debe tener entre ${constraints.USER_NICKNAME_MIN_LENGTH} y ${constraints.USER_NICKNAME_MAX_LENGTH} caracteres y sólo puede tener letras, números, guiones (-), guiones bajos (_), espacios o puntos.`,
	nicknameOrEmail: 'Nombre de usuario o email',
	no: 'No',
	noFieldsAvailable: 'No hay canchas para elegir',
	noFieldsFound: 'No se encontraron canchas',
	noFilesFound: 'No se encontraron archivos',
	noFriendlyMatchesYet: 'Aún no hay partidos amistosos',
	noGame: 'Fecha libre',
	noGoalKeeper: 'No hay arquero titular.',
	noImagesYet: 'Aún no hay imágenes',
	noMatches: 'Sin partidos',
	noMatchesYet: 'Aún no hay partidos',
	noMinutesInserted: 'No se ingresó el minuto',
	noNews: 'Sin noticias',
	noNewsYet: 'Sin noticias por ahora',
	noNotifications: 'Sin notificaciones',
	noPlacesAvailable: 'No hay complejos para elegir',
	noPeriodSelected: 'No se seleccionó tiempo',
	noPlaceDefined: 'Sin complejo definido',
	noPlayersYet: 'Aún no hay jugadores',
	noPlayerSelected: 'No se seleccionó jugador',
	noRefereesAvailableToMatch:
		'No hay árbitros disponibles para agregar al partido',
	noResults: 'Sin resultados',
	noSearchResults: 'No se encontraron resultados',
	noSearchMade: `Para buscar ingresá ${constraints.SEARCH_CHARACTERS_MIN} caracteres o más`,
	noStartDatetimeDefined: 'Sin fecha definida',
	noSecondsInserted: 'No se ingresó el segundo',
	noStagesFound: 'No se encontraron competencias',
	notAllowed: 'No permitido',
	notDefined: 'No definido',
	noTeamManager: 'No dirigente',
	noTeamSelected: 'No se seleccionó equipo',
	noTeamsYet: 'Aún no hay equipos',
	notEnoughPlayingPlayers: (amount: number) =>
		`No tiene suficientes jugadores, se precisan al menos ${amount}.`,
	notEnoughStarterPlayers: (amount: number) =>
		`No tiene suficientes jugadores titulares, se precisan al menos ${amount} y un arquero.`,
	notificationAlreadyUsed: 'La solicitud ya fue respondida',
	notificationIdNotFound: 'No se encontró la id de notificación',
	notificationNotAvailable: 'La solicitud ya no está disponible',
	notifications: 'Notificaciones',
	noTournamentsFound: 'No se encontraron torneos',
	notPlaying: 'No juega',
	noTypeSelected: 'No se seleccionó tipo',
	noUser: 'noUsuario',
	noUserConvertedToUser:
		'Para completar la conversión debe seguir el link que le enviamos a su email.',
	noUserDeleted: 'Se ha eliminado correctamente, te estamos redirigiendo.',
	noRelatedCoachPlayers: 'Jugadores no relacionados con el entrenador',
	noRelatedManagerTeams: 'Equipos no relacionados con el organizador',
	noRelatedManagerPlayers: 'Jugadores no relacionados con el organizador',
	noRelatedManagerReferees: 'Árbitros no relacionados con el organizador',
	notQualifies: 'No clasifica',
	notRegisteredYet: '¿No estás registrado? Entonces deberías ir a',
	notFindMatchesInCourse: 'No se encontraron partidos en directo',
	number: 'Número',
	numberOfInitialPenalties: 'Cantidad de penales de la tanda inicial',

	// --O--
	observations: 'Observaciones',
	of: 'de',
	ok: 'ok',
	onlyLeaguesCanBeCumulated: 'Sólo ligas pueden ser acumuladas',
	open: 'Abierto',
	openInMap: 'Abrir en el mapa',
	or: 'o',
	orDragFilesHere: 'O arrastrar archivos hacia acá',
	orDragImagesHere: 'O arrastrar imágenes hacia acá',
	organizations: 'Organizaciones',
	organizer: 'Organizador',
	organizers: 'Organizadores',
	ownGoal: 'Gol en contra',

	// --P--
	participantTeams: 'Equipos participantes',
	participantsAmount: 'Cantidad de participantes',
	participantsFrom: 'De',
	participants: 'participantes',
	participantsTo: ' a ',
	participatesIn: 'Participa en ',
	participationConfirmed: '¡La participación ha sido confirmada!',
	participationRejected: '¡La participación ha sido rechazada!',
	participations: 'Participaciones',
	participationListsNotConfirmed:
		'No se puede empezar el partido porque las listas de participación no están confirmadas.',
	participationListUpdated: 'Se actualizó la lista de participación',
	password: 'Contraseña',
	passwordNotValid: 'La contraseña debe tener 4 caracteres o más.',
	passwordUpdated: '¡La contraseña ha sido actualizada correctamente!',
	pausePeriod: 'Pausar',
	penalties: 'Penales',
	penaltiesNotFinished: 'Los penales deben tener un ganador',
	penaltyGoal: 'Convertido',
	penaltyMissed: 'Errado',
	penaltyPending: 'Penal pendiente',
	pending: 'Pendiente',
	period: 'Tiempo',
	periodText: (
		periodAmount: number,
		periodNumber: number,
		hasFinished: boolean
	) =>
		hasFinished
			? texts.rest
			: periodAmount >= periodNumber
			? periodAmount === 4
				? `Cuarto ${periodNumber}`
				: `Tiempo ${periodNumber}`
			: `Tiempo extra ${periodNumber - periodAmount}`,
	periodAmount: 'Cantidad de tiempos',
	periodDuration: 'Duración de cada tiempo (min)',
	periodsPerMatch: 'Cantidad de tiempos por partido',
	periodWillBeFinished: 'Se va a terminar el tiempo',
	periodWillBeStarted: 'Se va a iniciar el tiempo',
	periodWithNumber: (periodNumber: number) =>
		periodNumber === 1
			? 'Primer tiempo'
			: periodNumber === 2
			? 'Segundo tiempo'
			: `Tiempo ${periodNumber}`,
	phaseName: (phaseNumber: number) =>
		phaseNumber === 1
			? 'Finales'
			: phaseNumber === 2
			? 'Semifinales'
			: phaseNumber === 3
			? 'Cuartos de final'
			: `${2 ** phaseNumber / 2}avos de final`,
	personalFoul: 'Personal',
	pkGoal: 'Gol de penal',
	places: 'Complejos',
	placeName: 'Nombre del complejo / cancha',
	playedAt: (dateAsString: string) => `Jugado el ${dateAsString}`,
	playedInField: (fieldName: string) => `En la cancha: ${fieldName}`,
	player: 'Jugador',
	playerCard: 'Carné de Jugador',
	playerCardKebabCase: 'carne-jugador',
	players: 'Jugadores',
	playerAddedToTeam: 'El jugador ha sido agregado al equipo.',
	playerHasNoImagesYet: 'No tiene imágenes por ahora',
	playedMatchesAbreviation: 'PJ',
	playersParticipating: (amount: number) =>
		`${amount} jugador${amount !== 1 ? 'es' : ''} defiende${
			amount !== 1 ? 'n' : ''
		} esta camiseta`,
	playing: 'Juega',
	playoff: 'Playoff',
	playoffMatchCannotBeModifiedBecauseNextPhaseStarted:
		'No se pudo modificar el partido porque clasifica a una llave de la que ya se publicaron partidos.',
	playoffsWasPublished: '¡Los play-offs han sido publicados!',
	pleaseWaitWeAreRegisteringYou: 'Esperá, te estamos registrando',
	position: 'Pos',
	positionAmount: 'Cantidad de posiciones',
	positionsDefinition: 'Definición de posiciones',
	posGoalKeeper: 'Arquero',
	posCenterBack: 'Defensor central',
	posDefender: 'Defensor',
	posRightBack: 'Lateral derecho',
	posLeftBack: 'Lateral izquierdo',
	posDefendingMidfielder: 'Centrocampista defensivo',
	posMidfielder: 'Centrocampista',
	posRightMidfielder: 'Volante derecho',
	posLeftMidfielder: 'Volante izquierdo',
	posAttackingMidfielder: 'Volante ofensivo',
	posRightWing: 'Puntero derecho',
	posLeftWing: 'Puntero izquierdo',
	posAtacker: 'Delantero',
	posStriker: 'Delantero central',
	posSecondStriker: 'Falso 9',
	posPointGuard: 'Base',
	posShootingGuard: 'Ayuda base',
	posSmallForward: 'Alero',
	posPowerForward: 'Ala pivot',
	posCenter: 'Pivot',
	posSetter: 'Armador',
	posOutsideHitter: 'Punta',
	posOppositeHitter: 'Opuesto',
	posMiddleBlocker: 'Central',
	posLibero: 'Líbero',
	points: 'Pts',
	pointsAdjustmentCreated: 'Se creó el ajuste de puntos',
	pointsAdjustmentCreationExplanation:
		'Seleccioná el equipo, la cantidad de puntos a sumar o restar e ingresá una descripción (opcional) para crear un nuevo ajuste de puntos.',
	pointsAdjustmentDescription:
		'El equipo ha tenido los siguientes ajustes de puntos en esta competencia:',
	pointsPositiveOrNegative: 'Puntos (+/-)',
	preview: 'Previsualización',
	previous: 'Anterior',
	primaryPosition: 'Posición primaria',
	printDate: 'Fecha de impresión',
	printMatchForm: 'Imprimir formulario',
	printPlayerCard: 'Descargar carné de jugador',
	profileImage: 'Imagen de perfil',
	province: 'Provincia/estado/departamento',
	publishLeague: 'Publicar liga',
	publishMatch: 'Publicar partido',
	publishPlayoffs: 'Publicar play-off',

	// --Q--
	qualifies: 'Clasifica',
	qualifiesTo: 'Clasifica a',
	qualifiesToGoldenCup: 'A copa de oro',
	qualifiesToSilverCup: 'A copa de plata',

	// --R--
	raffleTeamsAndMatches: 'Sortear orden',
	readQr: 'Leer QR',
	redCard: 'Tarjeta roja',
	redCards: 'Tarjetas rojas',
	referee: 'Árbitro',
	refereeAddedToMatch: 'El árbitro ha sido agreagado al partido',
	referees: 'Árbitros',
	refereeFirstAssistantSignature: 'Firma 1er Asistente',
	refereeSecondAssistantSignature: 'Firma 2do Asistente',
	refereeSignature: 'Firma del Árbitro',
	refereeSignatureClarification: 'Aclaración de Firma',
	refereeRemoved: 'El árbitro ha sido retirado',
	refereeDoesntBelongToMatch: 'El árbitro no está agregado al partido',
	refereeAlreadyBelongToMatch: 'El árbitro ya está agregado al partido',
	refereeNotAvailable: 'El árbitro no está disponible',
	register: 'Registrarse',
	registerNoUser: 'Registrar integrantes',
	registerIntoMT: 'Registrarse en MultiTorneos',
	registerWithGoogle: 'Registrarse usando Google',
	registerWithFacebook: 'Registrarse usando Facebook',
	rejectParticipation: 'Rechazar participación',
	reject: 'Rechazar',
	rejected: 'Rechazado',
	rejectParticipationInTournament: (stageName: string) =>
		`¡Rechazaron participar en ${stageName}!`,
	rejectRequestToJoinTeam: (userNickname: string, teamName: string) =>
		`¡Rechazaron la solicitud de ${userNickname} para unirse a ${teamName}!`,
	relatedCoachPlayers: 'Jugadores relacionados con el entrenador',
	relatedManagerTeams: 'Equipos relacionados con el organizador',
	relatedManagerPlayers: 'Jugadores relacionados con el organizador',
	relatedManagerReferees: 'Árbitros relacionados con el organizador',
	reminders: 'Recordatorios',
	remove: 'Retirar',
	removeAllImages: 'Quitar todas las imágenes',
	removeFoul: 'Sacar falta',
	removeLastSet: 'Quitar último set',
	removeLeague: 'Quitar liga',
	removeLastPosition: 'Eliminar última posición',
	removeSubstitution: 'Quitar cambio',
	removeStage: 'Eliminar competencia',
	removeMember: 'Quitar del equipo',
	removeTeam: 'Quitar equipo',
	removingStageCannotBeUndone:
		'Al eliminar la competencia no se podrá deshacer el cambio y tendrás que crear otra en caso de que quieras volver a tenerla.',
	removingTeam:
		'Al quitar al equipo de la competencia ya no participará pero podés invitarlo de vuelta más adelante si querés.',
	removingTournament:
		'No te preocupes, el torneo se podrá restaurar más adelante.',
	removingStage:
		'No te preocupes, la competencia se podrá restaurar más adelante.',
	removingReferee: 'Retirando árbitro del partido...',
	removeUser: 'Quitar usuario',
	requestedToJoinYourTeam: '¡Solicitaron unirse a tu equipo!',
	reSendEmail: 'Reenvío de email',
	reSendEmailHasBeenDone:
		'Se envió nuevamente el email, si todavía no lo ves en tu bandeja de entrada, revisá en "spam" por las dudas.',
	resettingMatchExplanation:
		'Al reiniciar el partido se va a perder toda la información ingresada (como goles, tarjetas y cambios) y no se podrá restaurar. También se perderán los puntos obtenidos por los equipos en este partido afectando la tabla de posiciones.',
	resetMatch: 'Reiniciar partido',
	resetTournament: 'Reiniciar torneo',
	resolveTies: 'Resolver empates',
	result: 'Resultado',
	resetingTournamentLoosesChanges:
		'Reiniciar el torneo hace que se pierdan todos los cambios realizados',
	rest: 'Descanso',
	restoreDatabase: 'Rest. BD',
	restoreEmailHasBeenSent:
		'¡Te hemos enviado un email para restaurar la contraseña!',
	restorePassword: 'Restaurar contraseña',
	restoreTeam: 'Restaurar equipo',
	restoreTournament: 'Restaurar torneo',
	resumePeriod: 'Reanudar',
	restoreStage: 'Restaurar competencia',
	return: 'Volver',
	revertHomeFields: 'Invertir localías',
	round: 'Fecha',
	roundOf: (number: number) => `${number}vo de final`,
	roundOf16: '8vo de final',
	roundOf8: '4to de final',
	roundAmount: 'Cantidad de fechas',
	roundWasPublished:
		'¡La fecha ha sido publicada! Ahora podés ver los partidos y gestionarlos en directo cuando llegue el momento de empezarlos',
	rules: 'Reglamentos',

	// --S--
	savePosition: (position: number) => `Guardar posición ${position}`,
	saveImages: 'Guardar imágenes',
	savePassword: 'Guardar contraseña',
	saveChanges: 'Guardar cambios',
	saveChangesQuestion: '¿Guardar cambios?',
	saveToEnable: 'Guardá para ver las posiciones',
	saveToDefineMatches: 'Guardá para poder definir los cruces',
	saveToTransition: 'Guardá para transicionar',
	saveTournament: 'Guardar torneo',
	savingChanges: 'Guardando cambios',
	score: 'Pts',
	scoreDiff: '+/- pts',
	scoreLastSet: 'Puntaje del set final',
	scoreNoLastSet: 'Puntaje de los sets',
	scorerReferee: 'Planillero',
	screens: 'Pantallas',
	search: 'Buscar',
	searchAndAddCoaches: 'Buscar entrenadores',
	searchAndAddCollaborators: 'Buscar y agregar colaboradores',
	searchAndAddPlayers: 'Buscar y agregar jugadores',
	searchAndAddReferees: 'Buscar y agregar árbitros',
	searchAndAddTeams: 'Buscar equipos',
	searchAssistantReferee: 'Buscar árbitro asistente',
	searchField: 'Buscar cancha',
	searchFields: 'Buscar canchas',
	searching: 'Buscando',
	searchLeague: 'Buscar liga',
	searchLinemanReferee: 'Buscar árbitro de línea',
	searchMainReferee: 'Buscar árbitro principal',
	searchNews: 'Buscar noticias',
	searchPlace: 'Buscar complejo',
	searchPlaces: 'Buscar complejos',
	searchPlayers: 'Buscar jugadores',
	searchReferee: 'Buscar árbitro',
	searchRefereesToAdd: 'Buscar árbitros para agregar',
	searchScorerReferee: 'Buscar planillero',
	searchSecondaryReferee: 'Buscar árbitro secundario',
	searchTeam: 'Buscar equipo',
	searchTeams: 'Buscar equipos',
	searchTeamToInvite: 'Buscar rival',
	selectAnOption: 'Seleccioná una opción',
	selectDate: 'Seleccionar fecha',
	selectField: 'Elegir cancha',
	selectFinalPositions: 'Acomodá las posiciones manualmente',
	selectHeaderBackgroundColor: 'Seleccionar color del fondo del cabezal',
	selectPenalty: 'Seleccionar penal',
	selectPlace: 'Agregar a un complejo existente',
	selectPlayer: 'Seleccionar jugador',
	selectRoleInTeam: 'Elegir rol en el equipo',
	selectTeam: 'Seleccionar equipo',
	selectLocalTeam: 'Seleccionar equipo local',
	selectLosingTeamDescription:
		'Se debe seleccionar el o los equipos que perdieron por walkover',
	selectLosingTeam: 'Seleccionar perdedor/es',
	selectTemplate: 'Seleccionar plantilla a usar',
	semiFinal: 'Semifinal',
	sendEmail: 'Enviar email',
	sendInvitation: 'Enviar invitación',
	set: 'Set',
	setAsManager: 'Convertir en dirigente',
	setCantFinishWithScores: (number: number, minScore: number) =>
		`El set número ${number} no puede ser terminado con el score ingresado. El set es a ${minScore}`,
	setNumber: '#',
	setsAmount: 'Cantidad de sets',
	setsWon: 'Sets ganados',
	setTeamLogo: 'Asignar logo',
	setCoverTeam: 'Asignar portada',
	setProfileImage: 'Usar de perfil',
	secondaryPosition: 'Posición secundaria',
	secondaryReferee: 'Secundario',
	secondaryRefereeNotInvited: 'El árbitro secundario no ha sido invitado.',
	secondaryRefereeNotAccepted:
		'El árbitro secundario no ha aceptado la participación.',
	secondaryRefereeRejected:
		'El árbitro secundario ha rechazado la participación.',
	seconds: 'segundos',
	secondsExtended: 'Segundos',
	section: 'Sección',
	setsDiff: '+/- sets',
	shareLink: 'Compartir enlace',
	shirtNumberInitials: 'NC',
	showAll: 'Mostrar todas',
	showFoulCard: 'Mostrar fouls',
	showObservation: (observation: Observation) =>
		`El jugador ${
			observation.playerFullName
		} recibió una sanción en el partido ${observation.teamName} vs ${
			observation.rivalTeamName
		} de la fecha ${observation.startDatetime} por ${
			observation.suspension === EnumSuspension.twoYellowCard
				? 'doble amarilla'
				: 'roja directa'
		}. `,
	showLiveMatches: 'Partidos en directo',
	shouldCompletePenaltyInfo: 'Debe completar la información del penal',
	sideText: (side: EnumSides) =>
		side === EnumSides.LOCAL ? texts.local : texts.visitor,
	signature: 'Firma',
	signOut: 'Me retiro',
	signOutMT: 'Retirarse de MultiTorneos',
	signOutSuccesfully: 'Se retiró al Usuario correctamente',
	signUp: 'Registrarse',
	silverCup: 'Copa de plata',
	since: 'Desde',
	soon: 'Próximamente',
	spkGoal: 'Gol de penal largo',
	sport: 'Deporte',
	sportist: 'Deportista',
	sportName: (sport?: EnumSports) => {
		if (!sport) return undefined;
		return sport === EnumSports.football11
			? texts.football11
			: sport === EnumSports.babyFootball
			? texts.babyFootball
			: sport === EnumSports.football5
			? texts.football5
			: sport === EnumSports.football7
			? texts.football7
			: sport === EnumSports.football8
			? texts.football8
			: sport === EnumSports.basketball
			? texts.basketball
			: texts.volleyball;
	},
	sportField: (sport: EnumSports) =>
		`Cancha de ${(texts.sportName(sport) ?? 'no definido').toLowerCase()}`,
	stage: 'Competencia',
	stageAmount: 'Cantidad de competencias',
	stageAlreadyFinished: 'La competencia ya está terminada',
	stageImage: 'Imagen de la competencia',
	stageName: 'Nombre de competencia',
	stageNameCannotBeEmpty: 'El nombre de la competencia no puede estar vacío.',
	stageMode: 'Modo de competencia',
	stages: 'Competencias',
	stageFinishedSuccessfully:
		'La competencia fue finalizada exitosamente, te estamos redirigiendo...',
	stagesFinished: 'Competencias finalizadas',
	stagesInCourse: 'Competencias en curso',
	stagesNotStarted: 'Competencias no iniciadas',
	stageRestored: 'La competencia fue restaurada.',
	stageDeleted: 'La competencia fue borrada.',
	standings: 'Posiciones',
	startDate: 'Fecha de inicio',
	startDatetime: 'Fecha y hora de inicio',
	startDateFor: ({
		isPlayer,
		isCollaborator,
		isCoach,
		isReferee,
	}: {
		isPlayer?: boolean;
		isCollaborator?: boolean;
		isCoach?: boolean;
		isReferee?: boolean;
	}) =>
		isPlayer
			? 'Inicio como jugador'
			: isCollaborator
			? 'Inicio como colaborador'
			: isCoach
			? 'Inicio como entrenador'
			: isReferee
			? 'Inicio como árbitro'
			: '',
	starter: 'Titular',
	starterGoalkeeper: 'Arquero titular',
	startingMatch: 'Iniciando partido',
	startingPeriod: 'Iniciando tiempo',
	startMatch: 'Iniciar partido',
	startPeriod: 'Iniciar tiempo',
	startsGoalkeeping: 'Entra de arquero',
	startsPlaying: 'Entra',
	statisticsInTeam: 'Estadísticas en el equipo',
	status: 'Estado',
	statusAskedToJoin: 'Solicitó unirse',
	statusInvited: 'Invitado',
	statusPending: 'Pendiente',
	statusRejected: 'Rechazado',
	stayLeague: 'Liga de permanencia',
	stays: 'Mantiene',
	staysIn: 'Se mantienen en: ',
	stillNoData: 'Aún sin datos',
	stopEditing: 'Finalizar edición',
	stopEditingMatch: 'Cancelar cambios',
	substitute: 'Suplente',
	substitution: 'Cambio',
	substitutionIn: 'Entra',
	substitutionOut: 'Sale',
	subtitle: 'Subtítulo',
	summary: 'resumen',
	surname: 'Apellido',
	symbolMinus: '-',
	symbolPlus: '+',

	// --T--
	tableName: 'Nombre de la tabla',
	team: 'Equipo',
	teamAcceptParticipation: (teamName: string, stageName: string) =>
		`${teamName} aceptó participar de ${stageName}. Podés ver la información de la competencia en`,
	teamAlreadyAddedToStage: 'El equipo ya está agregado en la competencia.',
	teamRejectParticipation: (teamName: string, stageName: string) =>
		`${teamName} rechazó participar de ${stageName}. Podés ver la información de la competencia en`,
	teamAmount: 'Cantidad de equipos',
	teams: 'Equipos',
	teamOf: 'Equipo de ',
	teamOrganizer: 'Dirigente',
	teamNotFoundInStage: 'El equipo no está en la competencia.',
	teamNotFound: 'Equipo no encontrado.',
	teamCompetitionGenderInvalidForStage:
		'El género de competencia del equipo no coincide con el de la competencia',
	teamCorrectlyAdded: 'El equipo fue agregado correctamente.',
	teamCorrectlyRemoved: 'El equipo fue quitado correctamente.',
	teamCreationNotAllowed:
		'No tenés permitido crear equipos, hablá con un administrador si querés crear uno o envianos un email.',
	teamInfo: 'Información del equipo',
	teamInfoWithName: (name: string) => `Info equipo ${name}`,
	teamInProcess: 'Equipo en proceso de formación',
	teamIdentifier: 'Id',
	teamJoinRequestSent: '¡Se envió el pedido para unirse al equipo!',
	teamName: 'Nombre del equipo',
	teamNameCannotBeEmpty: 'El nombre del equipo no puede estar vacío.',
	teamNameMaxLengthError: (maxLength: number, currentLength: number) =>
		`El nombre del equipo no puede tener más de ${maxLength} caracteres, actualmente tiene ${currentLength}.`,
	teamParticipationRejected: 'No participa',
	teamPhoto: 'Imagen del equipo',
	teamsAdded: (teamAmount: number) =>
		`equipo${teamAmount !== 1 ? 's' : ''} agregado${
			teamAmount !== 1 ? 's' : ''
		}.`,
	teamSuccessfullyCreated: '¡El equipo fue creado correctamente!',
	teamManager: 'Dirigente',
	teamManagersCantLeaveTeams:
		'No podés retirarte del equipo siendo dirigente pero podés pedirle a otro dirigente que te quite.',
	technicalFoul: 'Técnica',
	testingLink: 'Link en testing',
	testingAcceptLink: 'Link de Aceptación en testing',
	testingRejectLink: 'Link de Rechazo en testing',
	textMemberNotAsking:
		'No tenés una solicitud de participación pendiente, se puede invitar a participar del equipo.',
	textMemberNotInvited:
		'No tenés una invitación pendiente, por favor comunicate con algún dirigente del equipo.',
	textBelongingTournament: (
		belongingTournament: string,
		belongingTournamentSinceYear: string
	) =>
		`Participa en ${belongingTournament} desde el ${belongingTournamentSinceYear}`,
	textParticipationStatus: (participationStatus: EnumParticipationStatuses) =>
		participationStatus === 'ACCEPTED'
			? 'Aceptado'
			: participationStatus === 'PENDING'
			? 'Pendiente'
			: participationStatus === 'REJECTED'
			? 'Rechazado'
			: participationStatus === 'INVITED'
			? 'Invitado'
			: participationStatus === 'ASKED'
			? 'Pedido'
			: '',
	textForStageStatus: (stageStatus: EnumStageStatuses) =>
		stageStatus === 'NOT_STARTED'
			? 'No empezada'
			: stageStatus === 'IN_COURSE'
			? 'En curso'
			: stageStatus === 'FINISHED'
			? 'Finalizada'
			: '',
	theRequestToJoinTeamWasAccepted: (userNickname: string, teamName: string) =>
		`Se aceptó la solicitud de ${userNickname} para unirse a ${teamName}. Podés ver la lista de jugadores en `,
	theRequestToJoinTeamWasRejected: (userNickname: string, teamName: string) =>
		`Se rechazó la solicitud de ${userNickname} para unirse a ${teamName}. Podés ver la lista de jugadores en `,
	thereArePlayersWithNegativeShirtNumber: 'Hay jugadores con número negativo.',
	thereArePlayersWithoutShirtNumber: 'Hay jugadores sin número de camiseta.',
	thereArePendingPlayers: 'Hay jugadores pendientes.',
	thereAreRepeatedNumbers: 'Hay números de camiseta repetidos.',
	thirdPlaceDefinitionWillHaveNoEffect:
		'Cuidado: No habrá definición de 3er puesto mientras el playoff tenga menos de 4 equipos',
	thirdPlaceDefinition: 'Definición de 3er puesto',
	thirdPlaceMatch: 'Definición de 3er puesto',
	thirdPlace: ' 3er puesto',
	teamFouls: 'Faltas colectivas',
	termsAndConditions: 'Términos y condiciones',
	tieInPosition: (position: number) =>
		`Equipos igualados en la posición ${position}, es necesario definir las posiciones manualmente`,
	timeouts: 'Tiempos muertos',
	title: 'Título',
	titleRequired: 'El título es requerido',
	toLeagues: 'Pasaje a ligas',
	toManyPlayoffs: 'Copa Oro/Plata',
	tooMuchQualifieds: 'Hay más clasificados que el máximo permitido',
	tooMuchStarterPlayers: (amount: number) =>
		`Tiene demasiados jugadores titulares, se permiten máximo ${amount} y un arquero`,
	toPlayoff: 'Pasaje a playoff',
	totalGoals: 'Goles en total',
	tournament: 'Torneo',
	tournaments: 'Torneos',
	tournamentsAndStages: 'Torneos/Competencias',
	tournamentCreated: 'El torneo fue creado correctamente.',
	tournamentDeleted: 'El torneo fue eliminado.',
	tournamentImage: 'Imagen del torneo',
	tournamentName: 'Nombre de torneo',
	tournamentNameCannotBeEmpty: 'El nombre del torneo no puede estar vacío.',
	tournamentNameChanged: 'El nombre del torneo se cambió exitosamente.',
	tournamentNameError:
		'El nombre del torneo debe tener por lo menos 4 caracteres y estar compuesto por letras, números y: "-", "_" o ".".',
	tournamentManager: 'Organizador de torneos',
	tournamentManagersSaved: 'Se guardaron los organizadores de torneos',
	tournamentManagerCreatedMustCompleteRegistration:
		'El organizador de torneos ha sido invitado exitosamente, ahora debe seguir el link enviado a su email para completar el registro.',
	tournamentRestored: 'El torneo fue restaurado.',
	tournamentSaved: 'Se guardó el torneo.',
	tournamentStages: 'Competencias del torneo',
	transitionCumulativeLeague: 'Transicionar liga acumulativa',
	turn: 'Turno',
	type: 'Tipo',

	// --U--
	usagePlans: 'Planes de uso',
	usagePlansText1:
		'Podés registrarte en MultiTorneos sin costo y editar tu perfil, crear equipos, editar tus equipos y ver los torneos, competencias y partidos.',
	usagePlansText2:
		'Sólo para organizar torneos, competencias o partidos amistosos tenés que acceder a un plan de uso.',
	usageCustomPlanCard: {
		title: 'Plan personalizado',
		description:
			'Si preferís otra opción de pago que te resulte más conveniente (por ejemplo "pago por equipo", "pago anual", "pagos anticipados" o "pagos parcelados") envianos tu propuesta que la estudiaremos con gusto.',
		url: `mailto:${contactEmail}?subject=Me%20interesa%20un%20plan%20personalizado&body=Quiero%20usar%20MultiTorneos%20para...`,
	},
	usagePlansCards: [
		{
			title: 'Plan amigos',
			description:
				'Para quien organiza una competencia pequeña (¹) y quiere organizar su información con simplicidad.',
			cost: '<span class="font-bold text-3xl">Gratis</span>',
			subdescription:
				'Tocá en Contacto para enviarnos un email así te registramos como organizador de torneos y podés empezar a utilizar MultiTorneos.',
			summary: `Este plan te permite organizar un torneo con una sola competencia y acceder a su información así como habilitar que los espectadores vean los resultados de partidos en directo. Incluye la creación de equipos y planteles, con un único organizador.<br/><span class="font-bold">(1) Una liga de hasta 6 equipos o un playoff de hasta 8 equipos.</span>`,
			url: `mailto:${contactEmail}?subject=Me%20interesa%20el%20plan%20amigos&body=Quiero%20usar%20MultiTorneos%20para...`,
		},
		{
			title: 'Pago por torneo',
			description:
				'Para quien organiza un torneo corto (²) con varias competencias relacionadas entre sí.',
			summary:
				'Este plan te permite organizar todas las ligas y playoffs que necesites en un único torneo y acceder a las clasificaciones, cruces y resultados de partidos en directo, con autorregistro de usuarios, creación de equipos con sus planteles y múltiples organizadores.<br/><span class="font-bold">(2) Torneos de hasta 2 semanas de duración.</span>',
			cost: '<span class="text-2xl"><span class="font-bold">U$S 0.5</span> por jugador por torneo</span>.',
			url: `mailto:${contactEmail}?subject=Me%20interesa%20el%20plan%20de%20pago%20por%20torneo&body=Quiero%20usar%20MultiTorneos%20para...`,
		},
		{
			title: 'Pago por período',
			description:
				'Para quien organiza torneos de larga duración (³) con muchas competencias en paralelo y con muchos partidos por participante.',
			summary:
				'Organizá todos los torneos que necesites de un deporte, sin límite de competencias. Podrás ver las llaves de los playoffs y las tablas de posiciones de las ligas. Además se transmitirán los resultados de partidos en directo y los usuarios podrán autorregistrarse y crear sus propios equipos.<br/><span class="font-bold">(3) Mínimo de 3 meses de contratación con pago por adelantado.</span><br/><span class="font-bold">(4) Participó al menos de un partido en el mes.</span>',
			cost: '<span class="text-2xl"><span class="font-bold">U$S 1</span> por jugador activo (⁴) por mes</span>.',
			url: `mailto:${contactEmail}?subject=Me%20interesa%20el%20plan%20de%20pago%20por%20período&body=Quiero%20usar%20MultiTorneos%20para...`,
		},
	],
	usedTimeouts: 'Tiempos muertos utilizados',
	user: 'Usuario',
	users: 'Usuarios',
	usersAndTeams: 'Usuarios / Equipos',
	userRegisteredSuccessfullyFollowTheLinkToCompleteRegistration:
		'El usuario ha sido invitado exitosamente, ahora debe seguir el link enviado a su email para completar el registro.',
	userRequestToJoinYourTeam: (userName: string, teamName: string) =>
		`${userName} solicitó unirse al equipo ${teamName}. Podés ver su perfil en `,
	userNeedsToFollowTheLinkToCompleteRegistration:
		'¡Te registraste correctamente! Ahora tenés que seguir el link que te enviamos al email y ya podrás ingresar en el sistema (si el email no está, revisá en "spam").',
	userRegisteredSuccessfulyRedirectingToYourProfile:
		'¡El registro ha sido exitoso! Te estamos redirigiendo a tu perfil...',
	passwordRestoredSuccessfulyRedirectingToYourProfile:
		'¡La contraseña se actualizó correctamente! Te estamos redirigiendo a tu perfil...',
	userRegisteredSuccessfuly:
		'¡Te registraste correctamente! Te estamos redirigiendo a tu perfil...',
	undefined: 'No definido',
	undefinedFemale: 'No definida',
	underConstruction: 'En construcción',
	undoInvitation: 'Retirar invitación',
	undoRequest: 'Retirar solicitud',
	unDeleteStage: 'Restaurar competencia',
	unfollow: 'No Seguir',
	unsetAsOrganizer: 'Quitar como directivo',
	unsetAsManager: 'Quitar como dirigente',
	updateCoverPhoto: 'Actualizar Foto de Portada',
	updatePassword: 'Actualizar contraseña',
	updateTournament: 'Guardar cambios',
	updatingParticipationList: 'Actualizando lista de participación...',
	uploadImage: 'Subir imagen',
	uploadImages: 'Subir imágenes',
	uploadVisual: 'Subir componente visual',

	// --V--
	versus: 'VS',
	vertical: 'Vertical',
	verticalNews: 'Noticia vertical',
	volleyball: 'Vóleibol',
	view: 'Ver',
	viewClub: 'Ver club',
	viewField: 'Ver cancha',
	viewLeague: 'Ver liga',
	viewLeaguePreview: 'Previsualizar liga',
	viewMainAdmin: 'Inicio',
	viewMainCoach: 'Inicio',
	viewMainPlayer: 'Inicio',
	viewMainCollaborator: 'Inicio',
	viewMainReferee: 'Inicio',
	viewMainFieldResponsible: 'Inicio',
	viewMainTournamentManager: 'Inicio',
	viewMatch: 'Ver partido',
	viewPlace: 'Ver complejo',
	viewPlayoff: 'Ver playoffs',
	viewPlayoffPreview: 'Previsualizar playoff',
	viewProfile: 'Ver perfil',
	viewStage: 'Ver competencia',
	viewStandings: 'Ver tabla de posiciones',
	viewTeam: 'Ver equipo',
	viewTournament: 'Ver torneo',
	viewTournamentPreview: 'Previsualizar torneo',
	viewUser: 'Ver usuario',
	visitor: 'Visitante',
	visitorScore: 'Puntaje del visitante',
	visual: 'Componente visual',

	// --W--
	waitForConfirmation: 'Esperar confirmación',
	walkover: 'Walkover',
	walkoverApplied: 'Se aplicó el walkover correctamente',
	walkoverConsequences:
		'Al confirmar el walkover se terminará el partido y el/los equipo/s seleccionados perderán.',
	warningYouAreNextToLeaveTeam: (teamName: string) =>
		`Cuidado: Estás por retirarte de ${teamName}`,
	warningYouAreNextToSignOutMT: 'Cuidado: Estás por retirarte de MultiTorneos',
	withExtraTimes: 'Con alargues',
	withoutContent: 'Sin contenido',
	withoutInformation: 'Sin información',
	withoutOpponent: 'Sin rival',
	withoutOrganizer: 'Sin organizador',
	withOutMatchesInCourse: 'Sin partidos en curso',
	withoutPlace: 'Sin cancha',
	withoutReferees: 'Sin árbitros',
	withoutTournamentManagers: 'No hay organizadores de torneos por ahora',
	withNotAcceptedReferees: 'Hay árbitros que no aceptaron',
	willAssist: 'Asistirá',
	willNotAssist: 'No asistirá',
	wonMatches: 'PG',
	wrongMatchSport: (matchSport: EnumSports) =>
		`Deporte incorrecto ${matchSport}, no se pudo continuar.`,

	// --Y--
	years: 'años',
	yellowCard: 'Tarjeta amarilla',
	yellowCards: 'Tarjetas amarillas',
	yes: 'Sí',
	yourPlayerParticipationStatus: 'Asistencia de tus jugadores',
	youAreInvitedToJoinATeam: '¡Te invitaron a unirte a un equipo!',
	youAreInvitedToBeMainReferee:
		'¡Te invitaron a ser árbitro principal en un partido!',
	youAreInvitedToBeSecondReferee:
		'¡Te invitaron a ser árbitro secundario en un partido!',
	youAreInvitedToBeAssistantReferee:
		'¡Te invitaron a ser árbitro asistente en un partido!',
	youAreInvitedToBeFootballAssistantReferee:
		'¡Te invitaron a ser árbitro de línea en un partido!',
	yourTeamWasInvitedToTournament:
		'¡Invitaron a tu equipo a participar de una competencia!',
	yourTeamWasInvitedToAFriendly: (teamName: string) =>
		`¡Invitaron a ${teamName} a un amistoso!`,

	// --Z--
	zone: 'Departamento',
};

export { texts, uris };
